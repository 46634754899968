const baseUrl = import.meta.env.VITE_API_BASE_URL

const getApiUrl = () => {
  if (baseUrl) {
    return "https://" + baseUrl;
  }
  return 'https://api.mestamaster.com';
}

const getWsUrl = () => {
  if (baseUrl) {
    return "wss://" + baseUrl;
  }
  return 'wss://api.mestamaster.com';
}

export default {
  API_URL: getApiUrl(),
  WS_URL: getWsUrl(),
};
