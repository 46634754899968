import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { type FieldError } from 'react-hook-form';
import { cn } from '@/utils';
import { useClickedOutside } from '@/hooks';
import { Tooltip, TooltipContent, TooltipTrigger, TextField } from '@atoms';
import { CheckIcon, Cross2Icon, PlusCircledIcon } from '@radix-ui/react-icons';

export type TaskCardAddEditFormProps = {
  taskId?: number;
  taskName?: string;
  onChange: (name: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
  fieldError?: FieldError;
};

export const TaskCardAddEditForm: React.FC<TaskCardAddEditFormProps> = ({
  taskId,
  taskName,
  onChange,
  onSubmit,
  onCancel,
  fieldError,
}) => {
  const { t } = useTranslation();
  const { ref, isClickedOutside } = useClickedOutside();

  useEffect(() => {
    if (isClickedOutside) {
      onCancel();
    }
  }, [isClickedOutside, onCancel]);

  return (
    <div
      ref={ref as React.RefObject<HTMLDivElement>}
      className={cn(
        'bg-background dark:bg-gray-800 my-2 first:my-0 pb-0',
        'border-2 border-slate-300'
      )}
    >
      <div className='px-2 pt-2'>
        <TextField
          value={taskName || ''}
          onChangeText={onChange}
          autoFocus
          status={fieldError && 'error'}
          onEnter={onSubmit}
          onKeyPress={(e) => {
            if (e.nativeEvent.key === 'Escape') {
              onCancel();
            }
          }}
        />
        <div className='flex flex-row-reverse py-1 items-center'>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                role='button'
                className='p-1 ml-1 hover:bg-slate-200 active:bg-slate-300'
                onClick={onCancel}
              >
                <Cross2Icon className='w-5 h-5 cursor-pointer text-red-700' />
              </div>
            </TooltipTrigger>
            <TooltipContent>{t('cancel')}</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                role='button'
                className='p-1 hover:bg-slate-200 active:bg-slate-300'
                onClick={onSubmit}
              >
                {taskId ? (
                  <CheckIcon className='w-5 h-5 cursor-pointer text-green-600' />
                ) : (
                  <PlusCircledIcon className='w-5 h-5 cursor-pointer text-green-600' />
                )}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {taskId
                ? t('taktTemplate:taskCard.renameTaskTemplate')
                : t('taktTemplate:taskCard.addTaskTemplate')}
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
