type SpinnerType = {
  width?: number;
  fullscren?: boolean;
};

const AnimatedSpinner: React.FC<Partial<SpinnerType>> = ({ width }) => (
  <svg
    width={width && `${width}px`}
    viewBox='0 0 164 163'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='animated-spinner'
  >
    <path
      className='fill-foreground'
      d='M127 12L139.368 7.87749C140.663 7.4458 142 8.40974 142 9.77485V22V140C142 141.105 142.895 142 144 142H155C156.105 142 157 142.895 157 144V155C157 156.105 156.105 157 155 157H142H129C127.895 157 127 156.105 127 155V29.7749C127 28.4097 125.663 27.4458 124.368 27.8775L113.368 31.5442C112.551 31.8164 112 32.5807 112 33.4415V155C112 156.105 111.105 157 110 157H97H82H69C67.8954 157 67 156.105 67 155V93.4415C67 92.5807 66.4491 91.8164 65.6325 91.5442L54.6325 87.8775C53.3374 87.4458 52 88.4097 52 89.7749V155C52 156.105 51.1046 157 50 157H37H22H9C7.89543 157 7 156.105 7 155V24C7 22.8954 7.89543 22 9 22H22H80C81.1046 22 82 22.8954 82 24V35C82 36.1046 81.1046 37 80 37H24C22.8954 37 22 37.8954 22 39V140C22 141.105 22.8954 142 24 142H35C36.1046 142 37 141.105 37 140V82V69.7749C37 68.4097 38.3374 67.4458 39.6325 67.8775L52 72L67 77L80.6325 81.5442C81.4491 81.8164 82 82.5807 82 83.4415V97V140C82 141.105 82.8954 142 84 142H95C96.1046 142 97 141.105 97 140V37V23.4415C97 22.5807 97.5509 21.8164 98.3675 21.5442L112 17L127 12Z'
    />
    <path
      className='fill-none stroke-background animate-mesta-spinner ease-spinner'
      strokeWidth={18}
      strokeDasharray={800}
      d='M82.5 30H15V150H44.5V78L74.5 88.5V150H105V27.5L134.5 17.5V150H157.5'
    />
  </svg>
);

export const Spinner: React.FC<SpinnerType> = ({ width = 164, fullscren = false }) => {
  if (!fullscren) return <AnimatedSpinner width={width} />;

  return (
    <div
      className='absolute z-10 h-full w-full flex items-center justify-center'
      data-testid='animated-spinner-fullscreen'
    >
      <div className='flex items-center'>
        <AnimatedSpinner width={100} />
      </div>
    </div>
  );
};
