import { useTheme } from '@/hooks';
import { cn } from '@/utils';
import { Button as PlasterButton, ButtonProps as PlasterButtonProps } from 'plaster-ui';

interface ButtonProps extends PlasterButtonProps {
  children?: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  onClick,
  disabled,
  preset,
  style,
  ...props
}) => {
  const { isDarkMode } = useTheme();
  return (
    <div className={cn(disabled && 'cursor-not-allowed', className)} data-testid='button'>
      <PlasterButton
        onPress={onClick}
        disabled={disabled}
        preset={disabled ? 'muted' : preset}
        darkMode={isDarkMode}
        style={style}
        {...props}
      >
        <span
          className={cn(
            'font-heading font-extrabold uppercase select-none',
            disabled && 'text-gray-400 cursor-not-allowed'
          )}
          data-testid='button-content'
        >
          {children}
        </span>
      </PlasterButton>
    </div>
  );
};
