import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HTTPBackend from 'i18next-http-backend';
import Backend from 'i18next-chained-backend';
import ResourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { sharedTranslations as dataPlumberTranslations } from 'data-plumber';
import { sharedTranslations as plasterUiTranslations } from 'plaster-ui';
import setDefaultOptions from 'date-fns/setDefaultOptions';

import { enGB as fnsEn, fi as fnsFi, ru as fnsRu } from 'date-fns/locale';

const dateFnsLocale = {
  en: fnsEn,
  fi: fnsFi,
  ru: fnsRu,
  'en-GB': fnsEn,
} as any;

const localResources = {
  en: {
    dataPlumber: { ...dataPlumberTranslations.en },
    plasterUi: { ...plasterUiTranslations.en },
  },
  fi: {
    dataPlumber: { ...dataPlumberTranslations.fi },
    plasterUi: { ...plasterUiTranslations.fi },
  },
};

// Fallback language in case of missing translation
export const fallbackLocale = 'en';

i18n
  .use(initReactI18next)
  // For loading translation files from server
  .use(Backend)
  // Detect browser language preferences
  .use(LanguageDetector)
  .init({
    backend: {
      backends: [HTTPBackend, ResourcesToBackend(localResources)],
    },
    fallbackLng: fallbackLocale,
    // Set "escapeValue" to false because not needed for React
    interpolation: {
      escapeValue: false,
    },
    // Separator between namespaces and key (e.g. t('auth:login'))
    nsSeparator: ':',
    // Declare all available namespaces
    ns: [
      'common',
      'validation',
      'auth',
      'company',
      'project',
      'taktTemplate',
      'spaces',
      'plasterUi',
      'dataPlumber',
      'spacetime',
      'teams',
      'perTakt',
      'register',
      'projectMembers',
      'projectSettings',
    ],
    // i18next will automatically resolve to this namespace
    // (e.g. t('header') equals to t('common:header'))
    defaultNS: 'common',
  });

export default i18n;

i18n.on('languageChanged', (lng) => {
  if (Object.keys(dateFnsLocale).includes(lng)) {
    setDefaultOptions({
      locale: dateFnsLocale[lng] || dateFnsLocale[fallbackLocale],
    });
  }
});
