import {
  __export
} from "./chunk-QXAXOUZS.mjs";

// src/data/models/index.ts
var models_exports = {};
__export(models_exports, {
  AuthenticatedUserModel: () => AuthenticatedUserModel,
  ChecklistItemModel: () => ChecklistItemModel,
  CommentModel: () => CommentModel,
  CompanyMembershipModel: () => CompanyMembershipModel,
  CompanyModel: () => CompanyModel,
  InterruptionModel: () => InterruptionModel,
  InterruptionReasons: () => InterruptionReasons,
  OwnMembershipInviteModel: () => OwnMembershipInviteModel,
  ProjectAnalyticsModel: () => ProjectAnalyticsModel,
  ProjectMembershipInviteModel: () => ProjectMembershipInviteModel,
  ProjectMembershipModel: () => ProjectMembershipModel,
  ProjectMembershipRequestModel: () => ProjectMembershipRequestModel,
  ProjectModel: () => ProjectModel,
  SpaceModel: () => SpaceModel,
  TaktModel: () => TaktModel,
  TaktTrainModel: () => TaktTrainModel,
  TaktTrainTemplateModel: () => TaktTrainTemplateModel,
  TaktWagonModel: () => TaktWagonModel,
  TaktWagonTemplateModel: () => TaktWagonTemplateModel,
  TaskTemplateModel: () => TaskTemplateModel,
  TeamMembershipModel: () => TeamMembershipModel,
  TeamModel: () => TeamModel,
  TodoEventModel: () => TodoEventModel,
  TodoModel: () => TodoModel,
  UserModel: () => UserModel
});

// src/data/models/user/User.ts
import { types as types4, flow as flow2, applySnapshot as applySnapshot2, toGenerator as toGenerator2 } from "mobx-state-tree";

// src/data/models/project/team/TeamMembership.ts
import { types } from "mobx-state-tree";
var TeamMembershipModel = types.model("TeamMembership").props({
  id: types.identifierNumber,
  teamId: types.number,
  userId: types.number
}).views((self) => ({})).actions((self) => ({}));

// src/data/utils/withApi.ts
import { getEnv } from "mobx-state-tree";
var withApi = (self) => ({
  views: {
    get mestamasterApi() {
      return getEnv(self).mestamasterApi;
    },
    get cableConnection() {
      return getEnv(self).cableConnection;
    }
  }
});

// src/data/models/project/project-memberships/ProjectMembershipInvite.ts
import { applySnapshot, flow, toGenerator, types as types3 } from "mobx-state-tree";

// src/data/fields/IsoDate.ts
import { types as types2 } from "mobx-state-tree";
function validateDate(str) {
  const date = Date.parse(str);
  if (isNaN(date))
    throw new Error("Invalid date");
  return new Date(date);
}
var IsoDate = types2.custom({
  name: "IsoDate",
  fromSnapshot(value) {
    return validateDate(value);
  },
  toSnapshot(value) {
    return value.toISOString();
  },
  isTargetType(maybeDate) {
    return maybeDate instanceof Date;
  },
  getValidationMessage(snapshot) {
    try {
      validateDate(snapshot);
      return "";
    } catch (error) {
      return error.message;
    }
  }
});

// src/data/utils/withRoot.ts
import { getRoot } from "mobx-state-tree";
var withRoot = (self) => ({
  views: {
    get root() {
      return getRoot(self);
    }
  }
});

// src/data/models/project/project-memberships/ProjectMembershipInvite.ts
var InviteCompany = types3.model("InviteCompany").props({
  id: types3.number,
  name: types3.string
});
var Inviter = types3.model("Inviter").props({
  id: types3.number,
  firstName: types3.string,
  lastName: types3.string
});
var InviteProject = types3.model("InviteProject").props({
  id: types3.number,
  name: types3.string,
  company: InviteCompany,
  address: types3.string
});
var ProjectMembershipInviteModel = types3.model("ProjectMembershipInvite").props({
  id: types3.identifierNumber,
  projectId: types3.number,
  inviterId: types3.number,
  state: types3.enumeration("State", ["pending", "accepted", "rejected"]),
  role: types3.enumeration("Role", ["member", "manager", "guest"]),
  createdAt: IsoDate,
  project: InviteProject,
  inviter: Inviter,
  phoneNumber: types3.maybeNull(types3.string),
  email: types3.maybeNull(types3.string)
}).extend(withApi).extend(withRoot).views((self) => ({})).actions((self) => ({
  resend: flow(function* () {
    const response = yield* toGenerator(self.mestamasterApi.projectMemberships.resendProjectMembershipInvite(self.id));
    return response;
  }),
  accept: flow(
    function* () {
      const response = yield* toGenerator(self.mestamasterApi.projectMemberships.acceptProjectMembershipInvite(self.id));
      if (response.kind === "ok") {
        applySnapshot(self, response.data);
        self.root.projectsStore.fetchProjects();
      }
      return response;
    }
  ),
  reject: flow(
    function* () {
      const response = yield* toGenerator(self.mestamasterApi.projectMemberships.rejectProjectMembershipInvite(self.id));
      if (response.kind === "ok") {
        applySnapshot(self, response.data);
      }
      return response;
    }
  ),
  partialUpdate: flow(
    function* (data) {
      const response = yield* toGenerator(self.mestamasterApi.projectMemberships.updateProjectMembershipInvite(self.id, data));
      if (response.kind === "ok") {
        applySnapshot(self, response.data);
      }
      return response;
    }
  )
}));
var OwnMembershipInviteModel = ProjectMembershipInviteModel.named("OwnMembershipInvite");

// src/data/models/user/User.ts
var UserModel = types4.model("User").props({
  id: types4.identifierNumber,
  firstName: types4.string,
  lastName: types4.string,
  email: types4.maybe(types4.string),
  lang: types4.maybeNull(types4.string),
  phoneNumber: types4.maybeNull(types4.string),
  teamMemberships: types4.optional(types4.array(TeamMembershipModel), [])
}).extend(withApi).views((self) => ({
  get fullName() {
    return `${self.firstName} ${self.lastName}`;
  },
  get abbreviatedName() {
    return `${self.firstName}${self.lastName ? " " + self.lastName[0] + "." : ""}`;
  },
  get teamMembershipsTeamIdArray() {
    return self.teamMemberships.map((teamMembership) => teamMembership.teamId);
  }
})).actions((self) => ({
  async setTeamMemberships(teamMemberships) {
    applySnapshot2(self.teamMemberships, teamMemberships);
  }
})).actions((self) => ({
  loadTeamMemberships: flow2(function* () {
    const response = yield* toGenerator2(self.mestamasterApi.fetchUserTeamMemberships(self.id));
    self.setTeamMemberships(response.data);
  }),
  fetchDetails: flow2(function* () {
    const response = yield* toGenerator2(self.mestamasterApi.users.getUser(self.id));
    if (response.kind === "ok") {
      applySnapshot2(self, response.data);
      return response;
    } else {
      console.error(`Error fetching User Details: ${JSON.stringify(response)}`, []);
      return response;
    }
  })
}));
var AuthenticatedUserModel = UserModel.named("AuthenticatedUser").props({
  gdprConsent: types4.maybe(types4.boolean),
  betaTester: types4.maybe(types4.boolean),
  onboarded: types4.maybe(types4.boolean),
  projectMembershipInvites: types4.optional(types4.array(OwnMembershipInviteModel), [])
}).views((self) => ({
  get openProjectMembershipInvites() {
    return self.projectMembershipInvites.filter((invite) => invite.state === "pending");
  }
})).actions((self) => ({
  fetchProjectMembershipInvites: flow2(function* () {
    const response = yield* toGenerator2(self.mestamasterApi.projectMemberships.fetchCurrentUserProjectMembershipInvites());
    if (response.kind === "ok") {
      applySnapshot2(self.projectMembershipInvites, response.data);
    } else {
      console.error(`Error fetching Project Membership Invites: ${JSON.stringify(response)}`, []);
    }
  })
}));

// src/data/models/company/Company.ts
import { applySnapshot as applySnapshot6, flow as flow5, toGenerator as toGenerator5, types as types7 } from "mobx-state-tree";

// src/data/models/company/CompanyMembership.ts
import { resolveIdentifier, types as types6 } from "mobx-state-tree";

// src/data/models/BaseRestModel.ts
import { types as types5 } from "mobx-state-tree";
var BaseRestModel = types5.model("BaseRestModel").props({
  id: types5.identifierNumber
}).extend(withApi).extend(withRoot);

// src/data/utils/withRestActions.ts
import { applySnapshot as applySnapshot4, destroy, detach, flow as flow3, toGenerator as toGenerator3 } from "mobx-state-tree";

// src/data/utils/applyPartialSnapshot.ts
import { applySnapshot as applySnapshot3, getSnapshot } from "mobx-state-tree";
var resolveDeepSnapshotMerge = (object, newSnapshot) => {
  const currentSnapshot = getSnapshot(object);
  const mergedSnapshot = { ...currentSnapshot, ...newSnapshot };
  return mergedSnapshot;
};
var applyPartialSnapshot = (object, snapshot) => {
  const newSnapshot = resolveDeepSnapshotMerge(object, snapshot);
  applySnapshot3(object, newSnapshot);
};

// src/data/utils/withRestActions.ts
var withMestamasterRestActions = (getRestModule) => {
  return (self) => ({
    partialUpdate: flow3(function* (data) {
      const response = yield* toGenerator3(
        getRestModule(self).partialUpdate(self.id, data)
      );
      if (response.kind === "ok" && response.data) {
        applyPartialSnapshot(self, response.data);
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    }),
    refresh: flow3(function* () {
      const response = yield* toGenerator3(
        getRestModule(self).get(self.id)
      );
      if (response.kind === "ok" && response.data) {
        applyPartialSnapshot(self, response.data);
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    }),
    update: flow3(function* (data) {
      const response = yield* toGenerator3(
        getRestModule(self).update(self.id, data)
      );
      if (response.kind === "ok" && response.data) {
        applySnapshot4(self, response.data);
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    }),
    delete: flow3(function* () {
      const response = yield* toGenerator3(
        getRestModule(self).delete(self.id)
      );
      if (response.kind === "ok") {
        detach(self);
        destroy(self);
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    })
  });
};

// src/data/models/company/CompanyMembership.ts
var CompanyMembershipModel = BaseRestModel.named("CompanyMembership").props({
  id: types6.identifierNumber,
  userId: types6.number,
  role: types6.enumeration("Role", ["member", "manager", "guest", "administrator"]),
  createdAt: IsoDate
}).actions(withMestamasterRestActions((self) => self.mestamasterApi.companyMemberships)).views((self) => ({
  get user() {
    return resolveIdentifier(UserModel, self.root, self.userId);
  }
}));

// src/data/models/company/Company.ts
var CompanyModel = types7.model("Company").props({
  id: types7.identifierNumber,
  name: types7.string,
  companyMemberships: types7.late(() => types7.optional(types7.array(CompanyMembershipModel), []))
}).extend(withApi).extend(withRoot).actions((self) => ({
  fetchCompanyMemberships: flow5(function* () {
    const response = yield* toGenerator5(self.mestamasterApi.companies.getCompanyMemberships(self.id));
    if (response.kind === "ok") {
      const users = response.data.map((companyMembership) => companyMembership.user);
      self.root.usersStore.addUsers(users);
      applySnapshot6(self.companyMemberships, response.data);
    }
    return response;
  })
}));

// src/data/models/project/Project.ts
import {
  applySnapshot as applySnapshot14,
  getSnapshot as getSnapshot3,
  flow as flow13,
  toGenerator as toGenerator13,
  types as types25,
  destroy as destroy5,
  detach as detach4,
  resolveIdentifier as resolveIdentifier13
} from "mobx-state-tree";

// src/data/utils/withSetPropAction.ts
var withSetPropAction = (mstInstance) => ({
  setProp(field, newValue) {
    mstInstance[field] = newValue;
  }
});

// src/data/models/project/todo/Todo.ts
import { resolveIdentifier as resolveIdentifier7, types as types14 } from "mobx-state-tree";

// src/data/models/project/todo/comment/Comment.ts
import { resolveIdentifier as resolveIdentifier2, types as types8 } from "mobx-state-tree";
var AttachmentModel = types8.model("Attachment").props({
  url: types8.string
});
var CommentModel = types8.model("Comment").props({
  id: types8.identifierNumber,
  content: types8.maybeNull(types8.string),
  createdAt: IsoDate,
  updatedAt: IsoDate,
  authorId: types8.number,
  attachments: types8.optional(types8.array(AttachmentModel), [])
}).extend(withRoot).views((self) => ({
  get author() {
    return resolveIdentifier2(UserModel, self.root, self.authorId);
  }
})).actions((self) => ({}));

// src/data/models/project/todo/interruption/Interruption.ts
import { resolveIdentifier as resolveIdentifier3, types as types9 } from "mobx-state-tree";
var InterruptionReasons = {
  materials: "materials",
  necessities: "necessities",
  plans: "plans",
  equipments: "equipments",
  prevTodo: "prev_todo",
  spaceNotAvailable: "space_not_available",
  spaceNotClean: "space_not_clean",
  other: "other",
  notDefined: "not_defined"
};
var InterruptionModel = types9.model("Interruption").props({
  id: types9.identifierNumber,
  state: types9.enumeration(["unresolved", "resolved"]),
  reason: types9.enumeration(Object.values(InterruptionReasons)),
  comment: types9.maybeNull(types9.string),
  createdAt: IsoDate,
  authorId: types9.number
}).extend(withRoot).views((self) => ({
  get author() {
    return resolveIdentifier3(UserModel, self.root.usersStore, self.authorId);
  }
})).actions((self) => ({}));

// src/data/models/project/space/Space.ts
import { types as types10 } from "mobx-state-tree";
var SpaceModel = types10.model("Space").props({
  id: types10.identifierNumber,
  name: types10.string,
  address: types10.string,
  description: types10.maybeNull(types10.string),
  order: types10.number,
  startsOn: types10.number,
  parentId: types10.maybeNull(types10.number)
}).views((self) => ({})).actions((self) => ({}));

// src/data/models/project/team/Team.ts
import {
  resolveIdentifier as resolveIdentifier4,
  types as types11
} from "mobx-state-tree";
var TeamModel = types11.model("Team").props({
  id: types11.identifierNumber,
  name: types11.string,
  color: types11.maybeNull(types11.string),
  order: types11.number,
  isManagerTeam: types11.optional(types11.boolean, false),
  teamMemberships: types11.optional(types11.array(TeamMembershipModel), []),
  analytics: types11.optional(
    types11.model({
      spaceCount: types11.number,
      taskCount: types11.number,
      totalHours: types11.number
    }),
    { spaceCount: 0, taskCount: 0, totalHours: 0 }
  )
}).extend(withRoot).extend(withApi).views((self) => ({
  get teamUsers() {
    return self.teamMemberships.map((membership) => {
      return resolveIdentifier4(UserModel, self.root, membership.userId);
    });
  }
}));

// src/data/models/project/todo/todoEvent/TodoEvent.ts
import { resolveIdentifier as resolveIdentifier5, types as types12 } from "mobx-state-tree";
var TodoEventModel = types12.model("TodoEvent").props({
  id: types12.identifierNumber,
  event: types12.enumeration([
    "started",
    "cancelled",
    "completed",
    "continued",
    "skipped",
    "unskipped",
    "interrupted",
    "resolved",
    "deleted"
  ]),
  createdAt: IsoDate,
  updatedAt: IsoDate,
  todoId: types12.number,
  userId: types12.maybe(types12.number)
}).extend(withRoot).views((self) => ({
  get author() {
    if (!self.userId)
      return null;
    return resolveIdentifier5(UserModel, self.root, self.userId);
  },
  get todo() {
    return resolveIdentifier5(TodoModel, self.root, self.todoId);
  }
})).actions((self) => ({}));

// src/data/models/project/todo/checklistItem/ChecklistItem.ts
import { applySnapshot as applySnapshot7, flow as flow7, resolveIdentifier as resolveIdentifier6, toGenerator as toGenerator7, types as types13 } from "mobx-state-tree";
var ChecklistItemModel = types13.model("ChecklistItem").props({
  id: types13.identifierNumber,
  completedAt: types13.maybeNull(IsoDate),
  userId: types13.maybeNull(types13.number),
  title: types13.string,
  createdAt: IsoDate,
  order: types13.optional(types13.number, 0)
}).extend(withRoot).extend(withApi).views((self) => ({
  get author() {
    if (!self.userId) {
      return null;
    }
    return resolveIdentifier6(UserModel, self.root, self.userId);
  }
})).actions((self) => ({
  toggle: flow7(function* () {
    if (self.completedAt) {
      const response = yield* toGenerator7(self.mestamasterApi.undoChecklistItem(self.id));
      applySnapshot7(self, response.data);
    } else {
      const response = yield* toGenerator7(self.mestamasterApi.completeChecklistItem(self.id));
      applySnapshot7(self, response.data);
    }
  }),
  updateSelf: (data) => {
    applySnapshot7(self, data);
  }
}));

// src/data/models/project/todo/Todo.ts
import { now } from "mobx-utils";
import { isAfter, formatDistanceToNow, isTomorrow, isToday, format, isYesterday } from "date-fns";
var TodoModel = types14.model("Todo").props({
  id: types14.identifierNumber,
  projectId: types14.number,
  taskName: types14.string,
  estimatedDuration: types14.number,
  waitingDuration: types14.number,
  state: types14.string,
  takt: types14.maybeNull(types14.number),
  taktWagonId: types14.maybeNull(types14.number),
  startsAt: IsoDate,
  dueAt: types14.maybeNull(IsoDate),
  completedAt: types14.maybeNull(IsoDate),
  spaceId: types14.number,
  teamId: types14.maybeNull(types14.number),
  taskDescription: types14.maybe(types14.string),
  note: types14.maybeNull(types14.string),
  lastModifiedByUserId: types14.maybeNull(types14.number),
  hasCheckupItems: types14.optional(types14.boolean, false),
  comments: types14.optional(types14.map(CommentModel), {}),
  todoEvents: types14.optional(types14.map(types14.late(() => TodoEventModel)), {}),
  interruptions: types14.optional(types14.map(InterruptionModel), {}),
  updatedAt: IsoDate,
  checklistItems: types14.optional(types14.array(ChecklistItemModel), [])
}).extend(withRoot).extend(withApi).views((self) => ({
  get checklistIsCompleted() {
    return self.checklistItems.every((item) => !!item.completedAt);
  },
  get estimatedHours() {
    return self.estimatedDuration / 60 * 10 / 10;
  },
  get todoEventArray() {
    return Array.from(self.todoEvents.values());
  },
  get feedItemsArray() {
    const items = [];
    items.push(...Array.from(self.interruptions.values()));
    self.todoEvents.forEach((todoEvent) => {
      if (todoEvent.event != "interrupted")
        items.push(todoEvent);
    });
    self.comments.forEach((comment) => items.push(comment));
    items.sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
    return items;
  },
  get orderedChecklistItems() {
    return self.checklistItems.slice().sort((a, b) => a.order - b.order);
  },
  get team() {
    if (!self.teamId)
      return void 0;
    return resolveIdentifier7(TeamModel, self.root, self.teamId);
  },
  get space() {
    return resolveIdentifier7(SpaceModel, self.root, self.spaceId);
  },
  get statusPaletteColor() {
    switch (self.state) {
      case "completed":
        return "success500";
      case "interrupted":
        return "angry500";
      case "started":
        return "primary200";
      default:
        return "floralWhite50";
    }
  },
  get interruptionArray() {
    return Array.from(self.interruptions.values());
  },
  get lastModifiedByUser() {
    if (!self.lastModifiedByUserId)
      return null;
    return resolveIdentifier7(UserModel, self.root, self.lastModifiedByUserId) || null;
  },
  humanDueAt(t) {
    let result = "";
    if (!self.dueAt)
      return result;
    const currentDate = now(6e3);
    const isDueAtMidnight = self.dueAt.getHours() === 23 && self.dueAt.getMinutes() === 59;
    const isDueToday = isToday(self.dueAt);
    const isDueTomorrow = isTomorrow(self.dueAt);
    const isDueYesterday = isYesterday(self.dueAt);
    const date = new Date(self.dueAt);
    if (isAfter(self.dueAt, currentDate)) {
      if (isDueToday) {
        result = t("today");
      } else if (isDueTomorrow) {
        result = t("tomorrow");
      } else {
        result = format(date, "dd/MM/yyyy");
      }
    } else {
      if (isDueYesterday) {
        result = t("yesterday");
      } else {
        result = formatDistanceToNow(date, { addSuffix: true });
        result = result + " (" + format(date, "dd/MM") + ")";
      }
    }
    if (!isDueAtMidnight) {
      result = `${result} ${t("at")} ${format(date, "HH:mm")}`;
    }
    return result;
  }
})).actions((self) => ({
  addInterruption(interruption) {
    self.interruptions.put(interruption);
  },
  setComments(comments) {
    comments.forEach((comment) => self.comments.put(comment));
  },
  setTodoEvents(todoEvents) {
    todoEvents.forEach((todoEvent) => self.todoEvents.put(todoEvent));
  },
  setInterruptions(interruptions) {
    interruptions.forEach((interruption) => self.interruptions.put(interruption));
  }
})).actions((self) => ({
  async loadDetails() {
    const response = await self.mestamasterApi.getTodoDetails(self.id);
    applyPartialSnapshot(self, response.data);
  },
  async loadComments() {
    const response = await self.mestamasterApi.getTodoComments(self.id);
    self.setComments(response.data);
  },
  async loadInterruptions() {
    const response = await self.mestamasterApi.getTodoInterruptions(self.id);
    self.setInterruptions(response.data);
  },
  async loadTodoEvents() {
    const response = await self.mestamasterApi.getTodoEvents(self.id);
    self.setTodoEvents(response.data);
  }
})).actions((self) => ({
  async start() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "start");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async createInterruption(comment, reason) {
    const response = await self.mestamasterApi.createTodoInterruption(self.id, comment, reason);
    self.addInterruption(response.data);
    self.loadDetails();
    self.loadTodoEvents();
    return response;
  },
  async cancel() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "cancel");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async complete() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "complete");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async continue() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "continue");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async skip() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "skip");
    applyPartialSnapshot(self, response.data);
    return response;
  },
  async unskip() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "unskip");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async resolve() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "resolve");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async createComment(comment, files) {
    const response = await self.mestamasterApi.createTodoComment(self.id, comment, files);
    self.setComments([response.data]);
    return response;
  }
})).actions((self) => ({
  async loadAllEvents() {
    await self.loadComments();
    await self.loadInterruptions();
    await self.loadTodoEvents();
  }
})).actions((self) => ({
  async loadAll() {
    await self.loadDetails();
    await self.loadAllEvents();
  }
}));

// src/data/models/project/takt/Takt.ts
import { types as types15 } from "mobx-state-tree";
import { now as now2 } from "mobx-utils";
var UPDATE_VIEWS_MILLISECONDS = 6e4;
var TaktModel = types15.model("Takt").props({
  taktKey: types15.identifier,
  startsAt: IsoDate,
  endsAt: IsoDate,
  taktIndex: types15.number,
  skipped: types15.optional(types15.boolean, false)
}).views((self) => ({
  get timeStatus() {
    const currentTime = now2(UPDATE_VIEWS_MILLISECONDS);
    if (self.endsAt.valueOf() < currentTime) {
      return "past";
    } else if (self.startsAt.valueOf() > currentTime) {
      return "future";
    } else {
      return "now";
    }
  }
})).actions((self) => ({}));

// src/data/models/project/project-settings/ProjectSettings.ts
import { types as types16 } from "mobx-state-tree";
var ProjectSettingsModel = types16.model("ProjectSettings").props({
  commentAttachmentsEnabled: types16.optional(types16.boolean, true)
}).views((self) => ({})).actions((self) => ({}));

// src/data/models/project/project-analytics/ProjectAnalytics.ts
import { types as types17 } from "mobx-state-tree";
var ProjectAnalyticsModel = types17.model("ProjectAnalytics").props({
  completedTodoCount: types17.maybe(types17.number),
  interruptedTodoCount: types17.maybe(types17.number),
  startedTodoCount: types17.maybe(types17.number),
  scheduleExpectedCompletedTodoCount: types17.maybe(types17.number),
  totalTodoCount: types17.maybe(types17.number)
}).views((self) => ({})).actions((self) => ({}));

// src/data/models/project/Project.ts
import { now as now3 } from "mobx-utils";

// src/data/models/project/takt/TaktTrain.ts
import { applySnapshot as applySnapshot10, flow as flow10, getParent, resolveIdentifier as resolveIdentifier11, toGenerator as toGenerator10, types as types22 } from "mobx-state-tree";

// src/data/models/project/takt/TaktWagon.ts
import { resolveIdentifier as resolveIdentifier8, types as types18 } from "mobx-state-tree";
var TaktWagonModel = types18.model("TaktWagon").props({
  id: types18.identifierNumber,
  startsAt: IsoDate,
  endsAt: IsoDate,
  workPackageId: types18.maybeNull(types18.number),
  taktTrainId: types18.number,
  taktIndex: types18.number,
  name: types18.string,
  todoIds: types18.optional(types18.array(types18.number), [])
}).extend(withRoot).views((self) => ({
  get taktTrain() {
    return resolveIdentifier8(TaktTrainModel, self.root, self.taktTrainId);
  },
  get todos() {
    return self.todoIds.map((id) => {
      return resolveIdentifier8(TodoModel, self.root, id);
    });
  },
  get totalEstimatedDuration() {
    const todos = self.todoIds.map((id) => {
      return resolveIdentifier8(TodoModel, self.root, id);
    });
    return todos.reduce((acc, todo) => acc + ((todo == null ? void 0 : todo.estimatedDuration) || 0), 0);
  }
})).views((self) => ({
  get todosByState() {
    return self.todos.reduce((acc, todo) => {
      if (!todo)
        return acc;
      if (!acc[todo.state])
        acc[todo.state] = [];
      acc[todo.state].push(todo);
      return acc;
    }, {});
  },
  get todosByTeamId() {
    return self.todos.reduce((acc, todo) => {
      if (!todo.teamId) {
        acc["0"] = acc["0"] || [];
        acc["0"].push(todo);
        return acc;
      }
      if (!acc[todo.teamId])
        acc[todo.teamId] = [];
      acc[todo.teamId].push(todo);
      return acc;
    }, {});
  },
  get isComplete() {
    if (self.todos.length == 0)
      return false;
    return self.todos.every((todo) => todo && (todo.state == "completed" || todo.state == "skipped"));
  },
  get isEmpty() {
    return self.todos.length == 0;
  }
})).actions((self) => ({}));

// src/data/models/project/takt-train-template/TaktTrainTemplate.ts
import {
  types as types21,
  applySnapshot as applySnapshot9,
  flow as flow9,
  toGenerator as toGenerator9,
  destroy as destroy3
} from "mobx-state-tree";

// src/data/models/project/takt-train-template/TaktWagonTemplate.ts
import {
  types as types20,
  flow as flow8,
  toGenerator as toGenerator8,
  applySnapshot as applySnapshot8,
  resolveIdentifier as resolveIdentifier10
} from "mobx-state-tree";

// src/data/models/project/takt-train-template/TaskTemplate.ts
import { resolveIdentifier as resolveIdentifier9, types as types19 } from "mobx-state-tree";
var TaskTemplateModel = types19.model("TaktWagonTemplate").props({
  id: types19.identifierNumber,
  name: types19.string,
  taktWagonTemplateId: types19.number,
  description: types19.optional(types19.string, ""),
  teamId: types19.maybeNull(types19.number),
  estimatedDuration: types19.number,
  order: types19.number,
  wagonOrder: types19.number
}).extend(withRoot).extend(withApi).views((self) => ({
  get teamColor() {
    var _a;
    return self.teamId ? (_a = resolveIdentifier9(TeamModel, self.root, self.teamId)) == null ? void 0 : _a.color : null;
  }
}));

// src/data/models/project/takt-train-template/TaktWagonTemplate.ts
var TaktWagonTemplateModel = types20.model("TaktWagonTemplate").props({
  id: types20.identifierNumber,
  name: types20.string,
  order: types20.number,
  buffer: types20.boolean,
  taskTemplates: types20.optional(types20.array(TaskTemplateModel), []),
  taktTrainTemplateId: types20.number
}).extend(withRoot).extend(withApi).views((self) => ({
  get taskTemplatesArray() {
    return self.taskTemplates.slice().sort((a, b) => a.wagonOrder - b.wagonOrder);
  },
  get totalEstimatedDuration() {
    return Array.from(self.taskTemplates.values()).reduce(
      (acc, taskTemplate) => acc + taskTemplate.estimatedDuration,
      0
    );
  }
})).actions((self) => ({
  addTaskTemplate(taskTemplate) {
    self.taskTemplates.push(taskTemplate);
  }
})).actions((self) => ({
  addTaskTemplates(taskTemplates) {
    taskTemplates.forEach((taskTemplate) => self.addTaskTemplate(taskTemplate));
  }
})).actions((self) => ({
  fetchTaskTemplates: flow8(function* () {
    const response = yield* toGenerator8(self.mestamasterApi.getTaskTemplates(self.id));
    if (response.kind === "ok" && response.data) {
      self.addTaskTemplates(response.data);
      applySnapshot8(self.taskTemplates, response.data);
    } else {
      console.error(`Error fetching task templates: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  createTaskTemplate: flow8(function* (taskTemplate) {
    const response = yield* toGenerator8(
      self.mestamasterApi.createTaskTemplate(self.id, taskTemplate)
    );
    if (response.kind === "ok" && response.data) {
      self.addTaskTemplate(response.data);
    } else {
      console.error(`Error creating task template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  updateTaskTemplate: flow8(function* (taskTemplateId, updatedTaskTemplate) {
    const existingTaskTemplate = resolveIdentifier10(
      TaskTemplateModel,
      self.root,
      taskTemplateId
    );
    const response = yield* toGenerator8(
      self.mestamasterApi.updateTaskTemplate(taskTemplateId, updatedTaskTemplate)
    );
    if (existingTaskTemplate && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTaskTemplate, response.data);
    } else {
      console.error(
        `Error updating task template: ${JSON.stringify(response)}`,
        existingTaskTemplate
      );
    }
    return response;
  })
})).actions((self) => ({
  deleteTaskTemplate: flow8(function* (taskTemplateId) {
    const response = yield* toGenerator8(self.mestamasterApi.deleteTaskTemplate(taskTemplateId));
    if (response.kind === "ok") {
      applySnapshot8(self.taskTemplates, self.taskTemplates.filter((task) => task.id !== taskTemplateId));
    } else {
      console.error(`Error delete task template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
}));

// src/data/models/project/takt-train-template/TaktTrainTemplate.ts
var TaktTrainTemplateModel = types21.model("TaktTrainTemplate").props({
  id: types21.identifierNumber,
  projectId: types21.number,
  name: types21.string,
  taktWagonTemplates: types21.optional(types21.map(TaktWagonTemplateModel), {}),
  spaceIds: types21.optional(types21.array(types21.number), [])
}).extend(withRoot).extend(withApi).views((self) => ({
  get taktWagonTemplatesArray() {
    return Array.from(self.taktWagonTemplates.values()).sort((a, b) => a.order - b.order);
  },
  get overallEstimatedDuration() {
    return Array.from(self.taktWagonTemplates.values()).reduce(
      (acc, taktWagonTemplate) => acc + taktWagonTemplate.totalEstimatedDuration,
      0
    );
  }
})).actions((self) => ({
  addTaktWagonTemplate(taktWagonTemplate) {
    const existingTaktWagonTemplate = self.taktWagonTemplates.get(
      taktWagonTemplate.id.toString()
    );
    if (existingTaktWagonTemplate) {
      applyPartialSnapshot(existingTaktWagonTemplate, taktWagonTemplate);
    } else {
      self.taktWagonTemplates.put(taktWagonTemplate);
    }
  },
  garbageCollectWagonTemplates(expectedIds) {
    Array.from(self.taktWagonTemplates.keys()).forEach((id) => {
      if (!expectedIds.includes(parseInt(id))) {
        destroy3(self.taktWagonTemplates.get(id));
      }
    });
  }
})).actions((self) => ({
  addTaktWagonTemplates(taktWagonTemplates, refresh = false) {
    taktWagonTemplates.forEach(
      (taktWagonTemplate) => self.addTaktWagonTemplate(taktWagonTemplate)
    );
    if (refresh) {
      self.garbageCollectWagonTemplates(taktWagonTemplates.map((t) => t.id));
    }
  }
})).actions((self) => ({
  fetchTaktWagonTemplates: flow9(function* (refresh = false) {
    const response = yield* toGenerator9(self.mestamasterApi.getTaktWagonTemplates(self.id));
    if (response.kind === "ok" && response.data) {
      self.addTaktWagonTemplates(response.data, refresh);
    } else {
      console.error(`Error fetching takt wagon templates: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchTaktTrainTemplateSpaces: flow9(function* () {
    const response = yield* toGenerator9(self.mestamasterApi.getTaktTrainsByTemplateId(self.id));
    if (response.kind === "ok" && response.data) {
      const taktTrains = response.data;
      applySnapshot9(
        self.spaceIds,
        taktTrains.map((taktTrain) => taktTrain.spaceId)
      );
    } else {
      console.error(`Error fetching takt train template spaces: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  createTaktWagonTemplate: flow9(function* (newTaktWagonTemplate) {
    const response = yield* toGenerator9(
      self.mestamasterApi.createTaktWagonTemplate(self.id, newTaktWagonTemplate)
    );
    if (response.kind === "ok" && response.data) {
      self.taktWagonTemplatesArray.forEach((wagon) => {
        if (wagon.order >= response.data.order) {
          wagon.order++;
        }
      });
      self.addTaktWagonTemplate(response.data);
    } else {
      console.error(`Error creating takt wagon template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  updateTaktWagonTemplate: flow9(function* (taktWagonTemplateId, updatedTaktWagonTemplate) {
    const existingTaktWagonTemplate = self.taktWagonTemplates.get(taktWagonTemplateId.toString());
    const response = yield* toGenerator9(
      self.mestamasterApi.updateTaktWagonTemplate(taktWagonTemplateId, updatedTaktWagonTemplate)
    );
    if (existingTaktWagonTemplate && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTaktWagonTemplate, response.data);
    } else {
      console.error(`Error update takt wagon template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  deleteTaktWagonTemplate: flow9(function* (taktWagonTemplateId) {
    const response = yield* toGenerator9(
      self.mestamasterApi.deleteTaktWagonTemplate(taktWagonTemplateId)
    );
    if (response.kind === "ok") {
      self.taktWagonTemplates.delete(taktWagonTemplateId.toString());
    } else {
      console.error(`Error delete takt wagon template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  afterCreate() {
    self.fetchTaktTrainTemplateSpaces();
    self.fetchTaktWagonTemplates();
  }
}));

// src/data/models/project/takt/TaktTrain.ts
var TaktTrainModel = types22.model("TaktTrain").props({
  id: types22.identifierNumber,
  startsAtTaktIndex: types22.number,
  spaceId: types22.number,
  passageId: types22.maybeNull(types22.number),
  taktWagons: types22.optional(types22.array(types22.late(() => TaktWagonModel)), [])
}).extend(withApi).extend(withRoot).views((self) => ({
  get orderedWagons() {
    return self.taktWagons.slice().sort((a, b) => a.taktIndex - b.taktIndex);
  },
  get space() {
    return resolveIdentifier11(SpaceModel, self.root, self.spaceId);
  },
  get trainTemplate() {
    if (!self.passageId)
      return null;
    return resolveIdentifier11(TaktTrainTemplateModel, self.root, self.passageId);
  }
})).actions((self) => ({
  updateStartsAtTaktIndex: flow10(function* (startsAtTaktIndex) {
    const response = yield* toGenerator10(self.mestamasterApi.updateTaktTrain(self.id, { startsAtTaktIndex }));
    applySnapshot10(self, response.data);
    return response;
  }),
  loadOwnTodos: flow10(function* () {
    const response = yield* toGenerator10(self.mestamasterApi.getTaktTrainTodos(self.id));
    const project = getParent(getParent(self));
    project == null ? void 0 : project.addTodos(response.data);
    return response;
  }),
  delete: flow10(function* () {
    const response = yield* toGenerator10(self.mestamasterApi.deleteTaktTrain(self.id));
    if (response.kind === "ok") {
      const parent = getParent(self, 2);
      parent.removeTaktTrain(self);
    }
    return response;
  })
}));

// src/data/utils/withMapTools.ts
import { getType, applySnapshot as applySnapshot11, getSnapshot as getSnapshot2, detach as detach3 } from "mobx-state-tree";
var applyMapPartialSnapshot = (node, snapshot) => {
  const currentSnapshot = getSnapshot2(node);
  const newSnapshot = { ...currentSnapshot, ...snapshot };
  return applySnapshot11(node, newSnapshot);
};
var withMapTools = (mstInstance) => ({
  addOrUpdateEntityToMap(node, element) {
    const mapType = getType(node);
    const idAttribute = mapType == null ? void 0 : mapType.mapIdentifierAttribute;
    if (!idAttribute) {
      throw new Error("Map type must have an identifier attribute");
    }
    const nodeId = (element == null ? void 0 : element.identifier) || element[idAttribute];
    if (node.has(nodeId)) {
      applyPartialSnapshot(getSnapshot2(node.get(nodeId)), element);
    } else {
      node.put(element);
    }
  },
  addOrUpdateMultipleEntitiesToMap(node, elements, shallowMerge) {
    const mapType = getType(node);
    const idAttribute = mapType == null ? void 0 : mapType.mapIdentifierAttribute;
    if (!elements) {
      return;
    }
    if (!idAttribute) {
      throw new Error("Map type must have an identifier attribute");
    }
    const mapSnapshot = elements.reduce((acc, element) => {
      const nodeId = (element == null ? void 0 : element.identifier) || element[idAttribute];
      if (!shallowMerge && node.has(nodeId)) {
        acc[nodeId] = resolveDeepSnapshotMerge(node.get(nodeId), element);
        return acc;
      }
      acc[nodeId] = element;
      return acc;
    }, {});
    applyMapPartialSnapshot(node, mapSnapshot);
  },
  cleanupMap(node, expectedIds) {
    const mapType = getType(node);
    const idAttribute = mapType == null ? void 0 : mapType.mapIdentifierAttribute;
    node.forEach((element, key) => {
      const nodeId = (element == null ? void 0 : element.identifier) || element[idAttribute];
      if (!expectedIds.includes(nodeId)) {
        detach3(node.get(key));
        node.delete(key);
      }
    });
  }
});

// src/data/utils/withApiHelpers.ts
var withApiHelpers = (mstInstance) => ({
  fetchPaginatedResource: async (fetchPageCall, onPageLoaded, onCompleted, onPageError) => {
    const dataAccumulator = [];
    let response = await fetchPageCall(1);
    dataAccumulator.push(...response.data);
    if (response.kind === "ok") {
      if (response.data) {
        await onPageLoaded(response);
        let page = 2;
        while (response.meta.pagination.next !== null) {
          response = await fetchPageCall(page);
          if (response.kind === "ok") {
            onPageLoaded(response);
            dataAccumulator.push(...response.data);
            page++;
          } else {
            if (onPageError) {
              onPageError(response);
            } else {
              console.error(`Error fetching paginated resource: ${JSON.stringify(response)}`, []);
            }
            return response;
          }
        }
      }
    } else {
      console.error(`Error fetching todos: ${JSON.stringify(response)}`, []);
    }
    onCompleted && onCompleted(dataAccumulator);
    return { data: dataAccumulator, kind: "ok" };
  }
});

// src/data/models/project/project-memberships/ProjectMembershipRequest.ts
import { applySnapshot as applySnapshot12, flow as flow11, toGenerator as toGenerator11, types as types23 } from "mobx-state-tree";
var RequestUserModel = types23.model("RequestUser").props({
  id: types23.identifierNumber,
  email: types23.string,
  firstName: types23.string,
  lastName: types23.string,
  phoneNumber: types23.maybeNull(types23.string)
});
var ProjectMembershipRequestModel = types23.model("ProjectMembershipRequest").props({
  id: types23.identifierNumber,
  projectId: types23.number,
  userId: types23.number,
  state: types23.enumeration("State", ["pending", "approved", "rejected"]),
  user: types23.maybe(RequestUserModel)
}).extend(withApi).views((self) => ({})).actions((self) => ({
  approve: flow11(function* approve(role) {
    const response = yield* toGenerator11(self.mestamasterApi.projectMemberships.approveProjectMembershipRequest(self.id, role));
    applySnapshot12(self, response.data);
  }),
  reject: flow11(function* reject() {
    const response = yield* toGenerator11(self.mestamasterApi.projectMemberships.rejectProjectMembershipRequest(self.id));
    applySnapshot12(self, response.data);
  })
}));

// src/data/models/project/project-memberships/ProjectMembership.ts
import { resolveIdentifier as resolveIdentifier12, types as types24 } from "mobx-state-tree";
var ProjectMembershipModel = BaseRestModel.named("ProjectMembership").props({
  id: types24.identifierNumber,
  projectId: types24.number,
  userId: types24.number,
  role: types24.enumeration("Role", ["member", "manager", "guest"]),
  createdAt: IsoDate
}).actions(withMestamasterRestActions((self) => self.mestamasterApi.projectMemberships)).views((self) => ({
  get user() {
    return resolveIdentifier12(UserModel, self.root, self.userId);
  }
}));

// src/data/models/project/Project.ts
var UPDATE_VIEWS_MILLISECONDS2 = 6e4;
var TODO_PAGE_SIZE = 10;
var ProjectModel = BaseRestModel.named("Project").props({
  id: types25.identifierNumber,
  companyId: types25.number,
  address: types25.string,
  name: types25.string,
  code: types25.maybe(types25.string),
  startsAt: types25.maybeNull(IsoDate),
  endsAt: types25.maybeNull(IsoDate),
  projectSettings: types25.optional(ProjectSettingsModel, {}, [null, void 0]),
  archived: types25.optional(types25.boolean, false),
  taktDuration: types25.optional(types25.string, "P1D"),
  taktDurationDays: types25.optional(types25.union(types25.number, types25.float), 1),
  todos: types25.optional(types25.map(TodoModel), {}),
  spaces: types25.optional(types25.map(SpaceModel), {}),
  teams: types25.optional(types25.map(TeamModel), {}),
  takts: types25.optional(types25.map(TaktModel), {}),
  taktTrains: types25.optional(types25.map(TaktTrainModel), {}),
  taktTrainTemplates: types25.optional(types25.map(TaktTrainTemplateModel), {}),
  lastSeen: types25.maybeNull(IsoDate),
  lastTodoCursor: types25.maybe(types25.number),
  analytics: types25.optional(ProjectAnalyticsModel, {}),
  projectLoaded: types25.optional(types25.boolean, false),
  projectMemberships: types25.optional(types25.array(ProjectMembershipModel), []),
  projectMembershipInvites: types25.optional(types25.array(ProjectMembershipInviteModel), []),
  projectMembershipRequests: types25.optional(types25.array(ProjectMembershipRequestModel), []),
  latestTodoEventId: types25.maybeNull(types25.number)
}).actions(withSetPropAction).actions(withMapTools).actions(withApiHelpers).actions(withMestamasterRestActions((self) => self.mestamasterApi.projects)).actions((self) => ({
  addTodo(todo) {
    self.addOrUpdateEntityToMap(self.todos, todo);
  },
  addTeam(team) {
    self.addOrUpdateEntityToMap(self.teams, team);
  },
  addTakt(takt) {
    self.addOrUpdateEntityToMap(self.takts, takt);
  },
  addSpace(space) {
    self.addOrUpdateEntityToMap(self.spaces, space);
  },
  addTaktTrainTemplate(taktTrainTemplate) {
    self.addOrUpdateEntityToMap(self.taktTrainTemplates, taktTrainTemplate);
  },
  addProjectAnalytics(analytics) {
    applySnapshot14(self.analytics, analytics);
  }
})).actions((self) => ({
  addTodos(todos) {
    self.addOrUpdateMultipleEntitiesToMap(self.todos, todos);
  },
  addSpaces(spaces) {
    self.addOrUpdateMultipleEntitiesToMap(self.spaces, spaces);
  },
  addTeams(teams) {
    self.addOrUpdateMultipleEntitiesToMap(self.teams, teams);
  },
  addTakts(takts) {
    self.addOrUpdateMultipleEntitiesToMap(self.takts, takts);
  },
  addTaktTrains(taktTrains) {
    self.addOrUpdateMultipleEntitiesToMap(self.taktTrains, taktTrains);
  },
  addTaktTrainTemplates(taktTrainTemplates) {
    self.addOrUpdateMultipleEntitiesToMap(self.taktTrainTemplates, taktTrainTemplates);
  }
})).views((self) => ({
  get company() {
    return resolveIdentifier13(CompanyModel, self.root, self.companyId);
  },
  get todoEvents() {
    const todos = Array.from(self.todos.values());
    return todos.map((todo) => todo.todoEventArray).flat().sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  },
  get todosArray() {
    return Array.from(self.todos.values()).sort(
      (a, b) => {
        var _a, _b;
        return ((_a = a.startsAt) == null ? void 0 : _a.getTime()) - ((_b = b.startsAt) == null ? void 0 : _b.getTime()) || a.id - b.id;
      }
    );
  },
  todosBySpaceId(spaceId) {
    return Array.from(self.todos.values()).filter((todo) => todo.spaceId === spaceId);
  },
  get spaceArray() {
    return Array.from(self.spaces.values());
  },
  get spaceArraySorted() {
    return Array.from(self.spaces.values()).sort((a, b) => a.order - b.order);
  },
  get teamArray() {
    return Array.from(self.teams.values());
  },
  get teamArraySorted() {
    return Array.from(self.teams.values()).sort((a, b) => a.order - b.order);
  },
  getTeamById(teamId) {
    return self.teams.get(teamId.toString());
  },
  getTeamNameById(teamId) {
    var _a;
    return ((_a = self.teams.get(teamId.toString())) == null ? void 0 : _a.name) || "";
  },
  get teamColors() {
    return Array.from(self.teams.values()).reduce((acc, team) => {
      if (team.color !== null) {
        acc[team.id.toString()] = team.color;
      }
      return acc;
    }, {});
  },
  get taktArray() {
    return Array.from(self.takts.values()).sort((a, b) => a.taktIndex - b.taktIndex);
  },
  get taktsByIndex() {
    const array = [];
    Array.from(self.takts.values()).forEach((takt) => {
      array[takt.taktIndex] = takt;
    });
    return array;
  },
  get taktTrainArray() {
    return Array.from(self.taktTrains.values()).sort(
      (a, b) => a.startsAtTaktIndex - b.startsAtTaktIndex
    );
  },
  getTaktRangeArray(startTaktIndex, endTaktIndex) {
    return Array.from(self.takts.values()).filter(
      (takt) => takt.taktIndex >= startTaktIndex && takt.taktIndex <= endTaktIndex
    );
  },
  taktTrainsForSpace(spaceId) {
    return Array.from(self.taktTrains.values()).filter((train) => train.spaceId === spaceId);
  },
  get trainsBySpaceId() {
    return Array.from(self.taktTrains.values()).reduce((acc, train) => {
      if (train.spaceId) {
        if (acc[train.spaceId]) {
          acc[train.spaceId].push(train);
        } else {
          acc[train.spaceId] = [train];
        }
      }
      return acc;
    }, {});
  },
  get taktTrainTemplateArray() {
    return Array.from(self.taktTrainTemplates.values());
  },
  getSpace(spaceId) {
    return self.spaces.get(spaceId);
  },
  get currentTakt() {
    return Array.from(self.takts.values()).filter((x) => {
      var _a;
      return x.endsAt.valueOf() > now3(UPDATE_VIEWS_MILLISECONDS2) && ((_a = x.startsAt) == null ? void 0 : _a.valueOf()) < now3(UPDATE_VIEWS_MILLISECONDS2);
    })[0];
  },
  get notCompletedTodosArray() {
    return Array.from(self.todos.values()).filter((todo) => todo.state != "completed").sort((a, b) => {
      var _a, _b;
      return ((_a = a.startsAt) == null ? void 0 : _a.getTime()) - ((_b = b.startsAt) == null ? void 0 : _b.getTime());
    });
  },
  get upcomingAndRecentlyCompletedArray() {
    return Array.from(self.todos.values()).filter(
      (todo) => {
        var _a;
        return todo.state != "completed" && todo.state != "skipped" || todo.completedAt && self.lastSeen && todo.completedAt.valueOf() > ((_a = self.lastSeen) == null ? void 0 : _a.getTime());
      }
    ).sort((a, b) => {
      var _a, _b;
      return ((_a = a.startsAt) == null ? void 0 : _a.getTime()) - ((_b = b.startsAt) == null ? void 0 : _b.getTime());
    });
  },
  get projectAnalytics() {
    if (self.analytics.totalTodoCount === void 0)
      return void 0;
    return getSnapshot3(self.analytics);
  },
  getTodosByTeamIdAndTaktIndex(teamId, taktIndex) {
    return Array.from(self.todos.values()).filter(
      (todo) => todo.teamId === teamId && todo.takt === taktIndex
    );
  },
  getCompletedPercentageByTaktIndex(taktIndex) {
    const todos = Array.from(self.todos.values()).filter(
      (todo) => todo.takt === taktIndex && todo.state !== "skipped"
    );
    if (todos.length === 0)
      return 0;
    const completedTodos = todos.filter((todo) => todo.state === "completed");
    return completedTodos.length / todos.length * 100;
  },
  get standaloneTodos() {
    return Array.from(self.todos.values()).filter((todo) => !todo.taktWagonId);
  },
  standaloneTodosForSpace(spaceId) {
    return Array.from(self.todos.values()).filter(
      (todo) => !todo.taktWagonId && todo.spaceId === spaceId
    );
  }
})).views((self) => ({
  get firstTakt() {
    return self.taktsByIndex[1];
  },
  get lastTakt() {
    return self.taktsByIndex[self.taktsByIndex.length - 1];
  },
  get latestTodoEvent() {
    if (!self.latestTodoEventId)
      return void 0;
    return self.todoEvents.find((event) => event.id === self.latestTodoEventId);
  },
  get scheduledCutoff() {
    if (self.currentTakt)
      return self.currentTakt.endsAt.valueOf();
    return now3(UPDATE_VIEWS_MILLISECONDS2) + 10 * 60 * 60;
  },
  getTaktStatus(taktIndex) {
    const takt = self.taktsByIndex[taktIndex];
    if (!takt)
      return "uncompleted";
    const perTaktTodos = Array.from(self.todos.values()).filter(
      (todo) => todo.takt === taktIndex && todo.state !== "skipped"
    );
    let allCompleted = true;
    for (const item of perTaktTodos) {
      if (item.state === "interrupted") {
        return "interrupted";
      }
      if (item.state !== "completed") {
        allCompleted = false;
      }
    }
    if (self.currentTakt && allCompleted)
      return takt.startsAt.valueOf() > now3(UPDATE_VIEWS_MILLISECONDS2) ? "uncompleted" : "completed";
    return allCompleted ? "completed" : "uncompleted";
  }
})).views((self) => ({
  get blockedTodosArray() {
    return Array.from(self.todos.values()).filter((todo) => todo.state == "interrupted").sort((a, b) => {
      const aTime = a.dueAt ? a.dueAt.getTime() : 0;
      const bTime = b.dueAt ? b.dueAt.getTime() : 0;
      return aTime - bTime;
    });
  },
  get lateTodosArray() {
    return self.upcomingAndRecentlyCompletedArray.filter((todo) => {
      return todo.dueAt && todo.dueAt.valueOf() <= now3(UPDATE_VIEWS_MILLISECONDS2) && todo.state != "interrupted";
    });
  },
  get scheduledNotInterruptedTodosArray() {
    return self.upcomingAndRecentlyCompletedArray.filter((todo) => {
      return todo.dueAt && todo.dueAt.valueOf() > now3(UPDATE_VIEWS_MILLISECONDS2) && todo.dueAt.valueOf() <= self.scheduledCutoff && todo.state != "interrupted";
    });
  },
  get upcomingNotInterruptedTodosArray() {
    return self.upcomingAndRecentlyCompletedArray.filter((todo) => {
      return todo.dueAt && todo.dueAt.valueOf() > now3(UPDATE_VIEWS_MILLISECONDS2) && todo.dueAt.valueOf() > self.scheduledCutoff && todo.state != "interrupted";
    });
  },
  get recentlyCompletedTodosArray() {
    return self.todosArray.filter((todo) => {
      return todo.completedAt && todo.completedAt.valueOf() > now3(UPDATE_VIEWS_MILLISECONDS2) - 5 * 60 * 60;
    });
  }
})).views((self) => ({
  blockedTodosArrayForUser(user) {
    return self.blockedTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  lateTodosArrayForUser(user) {
    return self.lateTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  scheduledNotInterruptedTodosArrayForUser(user) {
    return self.scheduledNotInterruptedTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  upcomingTodosArrayForUser(user) {
    return self.upcomingNotInterruptedTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  get pendingMembershipRequests() {
    return self.projectMembershipRequests.filter((request) => request.state === "pending");
  },
  get pendingMembershipInvites() {
    return self.projectMembershipInvites.filter((invite) => invite.state === "pending");
  }
})).actions((self) => ({
  setLatestTodoEvent(event) {
    self.latestTodoEventId = event.id;
  },
  setProjectLoaded(state) {
    self.projectLoaded = state;
  },
  removeTaktTrain(train) {
    detach4(train);
    self.taktTrains.delete(train.id.toString());
    destroy5(train);
  }
})).actions((self) => ({
  fetchTeamAnalytics: flow13(function* () {
    const response = yield* toGenerator13(self.mestamasterApi.getTeamsAnalytics(self.id));
    if (response.kind === "ok" && response.data) {
      self.teams.forEach((team) => {
        applyPartialSnapshot(team.analytics, response.data[team.id]);
      });
    } else {
      console.error(`Error fetching team analytics: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  createTaktTrain: flow13(function* (spaceId, startsAtTaktIndex, taktTrainTemplateId) {
    var _a;
    const newTaktTrain = {
      spaceId,
      startsAtTaktIndex,
      taktTrainTemplateId
    };
    const response = yield* toGenerator13(
      self.mestamasterApi.createTaktTrain(self.id, newTaktTrain)
    );
    if (response.kind === "ok") {
      self.addTaktTrains([response.data]);
      (_a = self.taktTrains.get(response.data.id.toString())) == null ? void 0 : _a.loadOwnTodos();
    }
  }),
  fetchTodo: flow13(function* (todoId) {
    const response = yield* toGenerator13(self.mestamasterApi.getTodoDetails(parseInt(todoId)));
    if (response.kind === "ok") {
      if (response.data.projectId == self.id) {
        self.addTodo(response.data);
      }
    }
    return null;
  }),
  fetchTodos: flow13(function* (filters, resetStore) {
    var _a;
    const response = yield* toGenerator13(self.mestamasterApi.getProjectTodos(self.id, filters));
    if (response.kind === "ok") {
      if (response.data) {
        if (resetStore) {
          const todos = response.data.reduce((acc, todo) => {
            acc[todo.id] = todo;
            return acc;
          }, {});
          applySnapshot14(self.todos, todos);
          self.setProp("lastTodoCursor", (_a = response.data[response.data.length - 1]) == null ? void 0 : _a.id);
        } else {
          self.addTodos(response.data);
        }
      }
    } else {
      console.error(`Error fetching todos: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  createStandaloneTodo: flow13(function* (newTodo) {
    const result = yield* toGenerator13(self.mestamasterApi.createProjectStandaloneTodo(self.id, newTodo));
    if (result.kind === "ok" && result.data) {
      self.addTodo(result.data);
    }
    return result;
  }),
  fetchProjectAnalytics: flow13(function* () {
    const response = yield* toGenerator13(self.mestamasterApi.projects.getProjectAnalytics(self.id));
    if (response.kind === "ok") {
      if (response.data) {
        self.addProjectAnalytics(response.data.globalTodoAnalytics);
      }
      return response;
    } else {
      console.error(`Error fetching project analytics: ${JSON.stringify(response)}`, []);
      return null;
    }
  })
})).actions((self) => ({
  resetAndFetchInitialTodosForUser: flow13(function* () {
    var _a;
    self.lastTodoCursor = void 0;
    let response = yield self.fetchTodos(
      {
        items: TODO_PAGE_SIZE,
        after: void 0,
        complete: false,
        own: true
      },
      true
    );
    if ((response == null ? void 0 : response.kind) === "ok") {
      self.lastTodoCursor = (_a = response.data[response.data.length - 1]) == null ? void 0 : _a.id;
    }
    return response;
  }),
  loadMoreOwnIncompleteTodos: flow13(function* () {
    var _a;
    const response = yield self.fetchTodos({
      cursor: self.lastTodoCursor,
      items: TODO_PAGE_SIZE,
      own: true,
      complete: false
    });
    self.lastTodoCursor = (_a = response.data[response.data.length - 1]) == null ? void 0 : _a.id;
    return response;
  }),
  loadAllTodos: flow13(function* () {
    var _a;
    let response = yield self.fetchTodos(
      {
        items: 200,
        after: void 0
      },
      false
    );
    while (response && response.meta.pagination.next) {
      let next_cursor = (_a = response.data[response.data.length - 1]) == null ? void 0 : _a.id;
      response = yield self.fetchTodos(
        {
          cursor: next_cursor,
          items: 200
        },
        false
      );
    }
    return;
  }),
  fetchAllSpaces: flow13(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getProjectSpaces(self.id, page, 100),
      (result) => {
        self.addSpaces(result.data);
      },
      (loadedResources) => {
        const loadedSpaceIds = loadedResources.map((space) => space.id);
        self.cleanupMap(self.spaces, loadedSpaceIds);
      }
    );
    return response;
  }),
  fetchAllTeams: flow13(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getProjectTeams(self.id, page, 100),
      (result) => {
        self.addTeams(result.data);
      },
      (loadedResources) => {
        const loadedTeamIds = loadedResources.map((team) => team.id);
        self.cleanupMap(self.teams, loadedTeamIds);
      }
    );
    self.fetchTeamAnalytics();
    return response;
  }),
  fetchAllTakts: flow13(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getProjectTakts(self.id, page, 100),
      (result) => {
        self.addTakts(result.data);
      }
    );
    return response;
  }),
  fetchAllTaktTrains: flow13(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getTaktTrains(self.id, page, 20),
      (result) => {
        self.addTaktTrains(result.data);
      },
      (loadedResources) => {
        const loadedTaktTrainIds = loadedResources.map((train) => train.id);
        self.cleanupMap(self.taktTrains, loadedTaktTrainIds);
      }
    );
    return response;
  }),
  fetchAllTaktTrainTemplates: flow13(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getTaktTrainTemplates(self.id),
      (result) => {
        self.addTaktTrainTemplates(result.data);
      },
      (loadedResources) => {
        const loadedTaktTrainTemplateIds = loadedResources.map((template) => template.id);
        self.cleanupMap(self.taktTrainTemplates, loadedTaktTrainTemplateIds);
      }
    );
    return response;
  }),
  updateSelf: flow13(function* () {
    self.latestTodoEventId = null;
    const response = yield self.mestamasterApi.projects.getProject(self.id.toString());
    if (response.kind === "ok") {
      applyPartialSnapshot(self, response.data);
    }
  }),
  handleTodoEvent: flow13(function* (data) {
    var _a;
    (_a = self.todos.get(data.todoId.toString())) == null ? void 0 : _a.loadAll();
  })
})).actions((self) => ({
  createSpace: flow13(function* (newSpace) {
    const result = yield* toGenerator13(self.mestamasterApi.createProjectSpace(self.id, newSpace));
    if (result.kind === "ok" && result.data) {
      self.addSpace(result.data);
    }
    return result;
  }),
  createTeam: flow13(function* (newTeam) {
    const result = yield* toGenerator13(self.mestamasterApi.createTeam(self.id, newTeam));
    if (result.kind === "ok" && result.data) {
      self.addTeam(result.data);
    } else {
      console.error(`Error creating team: ${JSON.stringify(result)}`, []);
    }
    return result;
  }),
  createTaktTrainTemplate: flow13(function* (newTaktTrainTemplate) {
    const result = yield* toGenerator13(
      self.mestamasterApi.createTaktTrainTemplate(self.id, newTaktTrainTemplate)
    );
    if (result.kind === "ok" && result.data) {
      self.addTaktTrainTemplate(result.data);
    } else {
      console.error(`Error creating takt train template: ${JSON.stringify(result)}`, []);
    }
    return result;
  }),
  cloneTaktTrainTemplate: flow13(function* (taktTrainTemplateToBeClonedId, clonedTrainData) {
    const cloningTaktTemplate = self.taktTrainTemplates.get(
      taktTrainTemplateToBeClonedId.toString()
    );
    const result = yield* toGenerator13(
      self.mestamasterApi.cloneTaktTrainTemplate(taktTrainTemplateToBeClonedId, clonedTrainData)
    );
    if (cloningTaktTemplate && result.kind === "ok" && result.data) {
      applySnapshot14(
        cloningTaktTemplate.spaceIds,
        cloningTaktTemplate.spaceIds.filter(
          (spaceId) => !clonedTrainData.spaceIds.includes(spaceId)
        )
      );
      self.addTaktTrainTemplate(result.data);
    } else {
      console.error(`Error cloning take train template: ${JSON.stringify(result)}`, []);
    }
    return result;
  })
})).actions((self) => ({
  updateSpace: flow13(function* (updatedSpaceId, updatedSpace) {
    const existingSpace = self.spaces.get(updatedSpaceId.toString());
    const response = yield* toGenerator13(
      self.mestamasterApi.updateSpace(updatedSpaceId, updatedSpace)
    );
    if (existingSpace && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingSpace, response.data);
    } else {
      console.error(`Error update space: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  updateTeam: flow13(function* (updatedTeamId, updatedTeam) {
    const existingTeam = self.teams.get(updatedTeamId.toString());
    const response = yield* toGenerator13(
      self.mestamasterApi.updateTeam(updatedTeamId, updatedTeam)
    );
    if (existingTeam && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTeam, response.data);
    } else {
      console.error(`Error updating team ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  updateTaktTrainTemplate: flow13(function* (taktTrainTemplateId, updatedTaktTrainTemplate) {
    const existingTaktTrainTemplate = self.taktTrainTemplates.get(taktTrainTemplateId.toString());
    const response = yield* toGenerator13(
      self.mestamasterApi.updateTaktTrainTemplate(taktTrainTemplateId, updatedTaktTrainTemplate)
    );
    if (existingTaktTrainTemplate && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTaktTrainTemplate, response.data);
    } else {
      console.error(`Error update takt train template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  updateSpaceOrder: flow13(function* (spaceId, newOrder, oldOrder) {
    const response = yield* toGenerator13(self.updateSpace(spaceId, { order: newOrder }));
    if (response.kind === "ok" && response.data) {
      self.spaceArraySorted.filter((space) => space.id !== spaceId).forEach((space) => {
        if (space.order >= newOrder && space.order < oldOrder) {
          space.order++;
        } else if (space.order <= newOrder && space.order > oldOrder) {
          space.order--;
        }
      });
    }
    return response;
  })
})).actions((self) => ({
  deleteSpace: flow13(function* (spaceId) {
    const response = yield* toGenerator13(self.mestamasterApi.deleteSpace(spaceId));
    if (response.kind === "ok") {
      self.spaces.delete(spaceId.toString());
      self.spaces.forEach((space) => {
        const spaceSortedIndex = self.spaceArraySorted.findIndex(
          (sortedSpace) => sortedSpace.id === space.id
        );
        space.order = spaceSortedIndex > -1 ? spaceSortedIndex : 0;
      });
    } else {
      console.error(`Error delete space: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  deleteTeam: flow13(function* (teamId) {
    const response = yield* toGenerator13(self.mestamasterApi.deleteTeam(teamId));
    if (response.kind === "ok") {
      self.teams.delete(teamId.toString());
      self.teams.forEach((team) => {
        const teamSortedIndex = self.teamArraySorted.findIndex(
          (sortedTeam) => sortedTeam.id === team.id
        );
        team.order = teamSortedIndex > -1 ? teamSortedIndex : 0;
      });
    } else {
      console.error(`Error delete team: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  deleteTaktTrainTemplate: flow13(function* (taktTrainTemplateId) {
    const response = yield* toGenerator13(
      self.mestamasterApi.deleteTaktTrainTemplate(taktTrainTemplateId)
    );
    if (response.kind === "ok") {
      self.taktTrainTemplates.delete(taktTrainTemplateId.toString());
    } else {
      console.error(`Error delete takt train template ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchProjectMembershipRequests: flow13(function* () {
    const response = yield* toGenerator13(
      self.mestamasterApi.projectMemberships.fetchProjectMembershipRequests(self.id)
    );
    if (response.kind === "ok" && response.data) {
      applySnapshot14(self.projectMembershipRequests, response.data);
    } else {
      console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchProjectMembershipInvites: flow13(function* () {
    const response = yield* toGenerator13(
      self.mestamasterApi.projectMemberships.fetchProjectMembershipInvites(self.id)
    );
    if (response.kind === "ok" && response.data) {
      applySnapshot14(self.projectMembershipInvites, response.data);
    } else {
      console.error(`Error fetching project membership invites: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchProjectMemberships: flow13(function* () {
    const response = yield* toGenerator13(
      self.mestamasterApi.projectMemberships.fetchProjectMemberships(self.id)
    );
    if (response.kind === "ok" && response.data) {
      applySnapshot14(self.projectMemberships, response.data);
    } else {
      console.error(`Error fetching project memberships: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  inviteToProject: flow13(function* (data) {
    const response = yield* toGenerator13(
      self.mestamasterApi.projectMemberships.inviteToProject({
        projectId: self.id,
        ...data
      })
    );
    if (response.kind === "ok") {
      self.projectMembershipInvites.push(response.data);
    } else {
      console.error(`Error inviting to project: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  refresh: flow13(function* () {
    self.setProjectLoaded(false);
    yield self.updateSelf();
    self.root.usersStore.fetchProjectUsers(self.id);
    yield self.fetchProjectMemberships();
    yield self.fetchAllTeams();
    yield self.fetchAllTakts();
    yield self.fetchAllSpaces();
    self.setProjectLoaded(true);
  })
}));

// src/data/stores/index.ts
var stores_exports = {};
__export(stores_exports, {
  AuthenticationStoreModel: () => AuthenticationStoreModel,
  CompaniesStoreModel: () => CompaniesStoreModel,
  ProjectsStoreModel: () => ProjectsStoreModel,
  RootStoreModel: () => RootStoreModel,
  SSOProvider: () => SSOProvider,
  UsersStoreModel: () => UsersStoreModel
});

// src/data/stores/UsersStore.ts
import {
  applySnapshot as applySnapshot15,
  flow as flow14,
  toGenerator as toGenerator14,
  types as types26
} from "mobx-state-tree";
var UsersStoreModel = types26.model("UsersStore").props({
  users: types26.optional(types26.map(UserModel), {})
}).extend(withApi).extend(withRoot).views((self) => ({
  get usersArray() {
    return Array.from(self.users.values());
  },
  get selectedProjectUsers() {
    var _a;
    const projectMemberships = (_a = self.root.projectsStore.selectedProject) == null ? void 0 : _a.projectMemberships;
    if (!projectMemberships)
      return [];
    return projectMemberships.map((membership) => self.users.get(membership.userId.toString()));
  }
})).actions((self) => ({
  addUser(user) {
    const existingUser = self.users.get(user.id.toString());
    if (existingUser) {
      applySnapshot15(existingUser, user);
    } else {
      self.users.put(user);
    }
  }
})).actions((self) => ({
  addUsers(users) {
    users.forEach((user) => {
      self.addUser(user);
    });
  }
})).actions((self) => ({
  fetchProjectUsers: flow14(function* (projectId) {
    const result = yield* toGenerator14(self.mestamasterApi.users.fetchProjectUsers(projectId));
    if (result.kind === "ok") {
      self.addUsers(result.data);
    }
  })
}));

// src/data/stores/CompaniesStore.ts
import {
  applySnapshot as applySnapshot16,
  flow as flow15,
  toGenerator as toGenerator15,
  types as types27
} from "mobx-state-tree";
var CompaniesStoreModel = types27.model("CompaniesStore").props({
  companies: types27.optional(types27.map(CompanyModel), {}),
  selectedCompany: types27.maybe(types27.reference(CompanyModel))
}).extend(withApi).views((self) => ({
  get companiesArray() {
    return Array.from(self.companies.values());
  }
})).actions((self) => ({
  addCompany(company) {
    const existingCompany = self.companies.get(company.id.toString());
    if (existingCompany) {
      applySnapshot16(existingCompany, company);
    } else {
      self.companies.put(company);
    }
  }
})).actions((self) => ({
  addCompanies(companies) {
    companies.forEach((company) => self.addCompany(company));
  }
})).actions((self) => ({
  fetchCompanies: flow15(function* () {
    const result = yield* toGenerator15(self.mestamasterApi.companies.getCompanies());
    if (result.kind === "ok") {
      self.addCompanies(result.data);
    }
    return result;
  })
})).actions((self) => ({
  selectCompany: flow15(function* (companyId) {
    if (!self.companies.has(companyId)) {
      const result = yield* toGenerator15(self.mestamasterApi.companies.getCompany(companyId));
      if (result.kind === "ok") {
        self.addCompany(result.data);
        self.selectedCompany = self.companies.get(companyId);
      }
    }
    if (!self.companies.has(companyId)) {
      console.error("Company not found");
      self.selectedCompany = void 0;
      return;
    }
    self.selectedCompany = self.companies.get(companyId);
  }),
  deselectCompany() {
    self.selectedCompany = void 0;
  }
}));

// src/data/stores/ProjectsStore.ts
import { types as types28, flow as flow16, resolveIdentifier as resolveIdentifier14, toGenerator as toGenerator16 } from "mobx-state-tree";
import { now as now4 } from "mobx-utils";
var ProjectsStoreModel = types28.model("ProjectsStore").props({
  projects: types28.optional(types28.map(ProjectModel), {}),
  selectedProject: types28.maybe(types28.reference(ProjectModel))
}).extend(withApi).actions(withApiHelpers).actions(withMapTools).views((self) => ({
  get projectsArray() {
    return Array.from(self.projects.values()).sort((a, b) => {
      var _a, _b;
      if (!a.endsAt || !b.endsAt) {
        return 0;
      }
      return ((_a = b.endsAt) == null ? void 0 : _a.valueOf()) - ((_b = a.endsAt) == null ? void 0 : _b.valueOf());
    });
  }
})).views((self) => ({
  get liveProjects() {
    return self.projectsArray.filter((project) => project.endsAt && !project.archived && project.endsAt.valueOf() > now4(6e4));
  },
  get oldProjects() {
    return self.projectsArray.filter((project) => project.endsAt && !project.archived && (project.endsAt === null || project.endsAt.valueOf() + 2 * 30 * 24 * 60 * 60 * 60 < now4(6e4)));
  },
  get archivedProjects() {
    return self.projectsArray.filter((project) => project.archived);
  },
  getProjectsByCompanyId(companyId) {
    return self.projectsArray.filter((project) => project.companyId === companyId);
  }
})).actions((self) => ({
  addProject(project) {
    const existingProject = self.projects.get(project.id.toString());
    if (existingProject) {
      applyPartialSnapshot(existingProject, project);
    } else {
      self.projects.put(project);
    }
  }
})).actions((self) => ({
  addProjects(projects) {
    projects.forEach((project) => self.addProject(project));
  }
})).actions((self) => ({
  fetchProjects: flow16(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.projects.list(100, page),
      (result) => {
        self.addProjects(result.data);
      },
      (loadedResources) => {
        const loadedProjectIds = loadedResources.map((project) => project.id);
        self.cleanupMap(self.projects, loadedProjectIds);
      }
    );
    return response;
  }),
  handleUpdatedTodoEvent: flow16(function* (data) {
    const todo = resolveIdentifier14(TodoModel, self, data.todoId);
    if (!todo) {
      console.error("Todo not found");
      return;
    }
    todo.loadDetails();
    const project = self.projects.get(todo.projectId);
    project == null ? void 0 : project.setLatestTodoEvent(data);
  }),
  handleChecklistItemUpdate: flow16(function* (data) {
    const checklist_item = resolveIdentifier14(ChecklistItemModel, self, data.id);
    if (!checklist_item) {
      return;
    }
    checklist_item.updateSelf(data);
  })
})).actions((self) => ({
  selectProject: flow16(function* (project_id) {
    if (!self.projects.has(project_id)) {
      const result = yield* toGenerator16(self.mestamasterApi.projects.getProject(project_id));
      if (result.kind === "ok") {
        self.addProject(result.data);
        self.selectedProject = self.projects.get(project_id);
      }
    }
    if (!self.projects.has(project_id)) {
      console.error("Project not found");
      self.selectedProject = void 0;
      return;
    }
    self.selectedProject = self.projects.get(project_id);
    if (self.selectedProject) {
      self.selectedProject.refresh();
    }
  }),
  deselectProject() {
    self.selectedProject = void 0;
  },
  listenToProject(projectId) {
    var _a, _b;
    (_a = self.cableConnection) == null ? void 0 : _a.subscribe(
      "V3ProjectTodoEventsChannel",
      { project_id: projectId },
      (data) => {
        self.handleUpdatedTodoEvent(data.data);
      }
    );
    (_b = self.cableConnection) == null ? void 0 : _b.subscribe(
      "V3ProjectChecklistItemEventsChannel",
      { project_id: projectId },
      (data) => {
        self.handleChecklistItemUpdate(data.data);
      }
    );
    console.debug("Subscribed events for project " + projectId);
  }
})).actions((self) => ({
  createProject: flow16(function* (companyId, newProject) {
    const result = yield* toGenerator16(
      self.mestamasterApi.projects.createProject(companyId, newProject)
    );
    if (result.kind === "ok") {
      self.addProject(result.data);
    }
    return result;
  }),
  toggleArchiveProject: flow16(function* (projectId, archived) {
    const result = yield* toGenerator16(
      self.mestamasterApi.projects.updateProject(projectId, { archived })
    );
    const existingProject = self.projects.get(projectId.toString());
    if (result.kind === "ok" && existingProject) {
      applyPartialSnapshot(existingProject, result.data);
    }
    return result;
  }),
  createProjectMembershipRequest: flow16(function* (code) {
    const result = yield* toGenerator16(
      self.mestamasterApi.projectMemberships.createProjectMembershipRequest(code)
    );
    return result;
  })
}));

// src/data/stores/AuthenticationStore.ts
import { types as types29, flow as flow17, applySnapshot as applySnapshot18, getEnv as getEnv2, toGenerator as toGenerator17 } from "mobx-state-tree";

// src/services/sso/oauth.ts
import cryptoJs from "crypto-js";
function generateCodeVerifier() {
  const length = 96;
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomValues = new Uint8Array(length);
  randomValues.forEach((_value, index) => {
    randomValues[index] = Math.floor(Math.random() * charset.length);
  });
  let result = "";
  for (let i = 0; i < length; i++) {
    result += charset.charAt(randomValues[i] % charset.length);
  }
  return result;
}
var EncodeBase64URL = (str) => {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};
async function generateCodeChallenge(oauthCodeVerifier) {
  const utf8Array = cryptoJs.enc.Utf8.parse(oauthCodeVerifier);
  const hashed = cryptoJs.SHA256(utf8Array);
  const hashBase64 = cryptoJs.enc.Base64.stringify(hashed);
  return EncodeBase64URL(hashBase64.split("=")[0]);
}
var getInitiationPKCEUrl = async (settings, redirectUri, oauthCodeVerifier) => {
  const state = Math.random().toString(36).substring(7);
  const challenge = await generateCodeChallenge(oauthCodeVerifier);
  const url = `${settings.authorizationEndpoint}?response_type=code&client_id=${settings.clientId}&redirect_uri=${redirectUri}&code_challenge=${challenge}&code_challenge_method=S256&state=${state}&scope=${settings.scope}`;
  return url;
};

// src/data/stores/AuthenticationStore.ts
var SSOProvider = types29.model("SSOProvider").props({
  provider: types29.string,
  oauth: types29.model({
    authorizationEndpoint: types29.string,
    tokenEndpoint: types29.string,
    clientId: types29.string,
    scope: types29.string
  })
});
var AuthenticationStoreModel = types29.model("AuthenticationStore").props({
  authenticatedUser: types29.maybeNull(AuthenticatedUserModel),
  authenticatedDeviceId: types29.maybeNull(types29.number),
  ssoProviders: types29.optional(types29.map(SSOProvider), {}),
  oauthCodeVerifier: types29.maybe(types29.string)
}).extend(withApi).extend(withRoot).views((store) => ({
  get isAuthenticated() {
    return !!store.authenticatedUser;
  },
  get isOnboarded() {
    var _a, _b;
    return !!((_a = store.authenticatedUser) == null ? void 0 : _a.gdprConsent) && !!((_b = store.authenticatedUser) == null ? void 0 : _b.onboarded);
  }
})).actions((store) => ({
  setAuthenticatedDeviceId(id) {
    store.authenticatedDeviceId = id;
  },
  setAuthenticatedUser(user) {
    if (user == null) {
      store.authenticatedUser = null;
      return;
    }
    if (store.authenticatedUser) {
      applySnapshot18(store.authenticatedUser, user);
    } else {
      store.authenticatedUser = user;
    }
  },
  async apiHasValidAuthToken() {
    return store.mestamasterApi.hasValidAuthToken();
  },
  connectCableConnection() {
    getEnv2(store).connectCableConnection();
  },
  disconnectCableConnection() {
    getEnv2(store).disconnectCableConnection();
  }
})).actions((store) => ({
  logoutDevice: flow17(function* () {
    if (!store.authenticatedDeviceId)
      return;
    const response = yield store.mestamasterApi.destroyDevice(store.authenticatedDeviceId);
    if (response.kind === "ok") {
      store.setAuthenticatedDeviceId(null);
    }
  })
})).actions((store) => ({
  restAuthStore: flow17(function* () {
    yield store.logoutDevice();
    store.setAuthenticatedUser(null);
  }),
  async setApiAuthToken(authToken) {
    await store.mestamasterApi.setAuthToken(authToken);
  }
})).actions((store) => ({
  logout: flow17(function* () {
    yield store.restAuthStore();
    store.setApiAuthToken("");
    store.disconnectCableConnection();
    const rootStore = store.root;
    if (rootStore) {
      rootStore.reset();
    }
  })
})).actions((store) => ({
  registerDevice(platform, expoToken, deviceToken) {
    store.mestamasterApi.createDevice(platform, expoToken, deviceToken).then((response) => {
      if (response.kind === "ok") {
        store.setAuthenticatedDeviceId(response.data.id);
      }
    });
  },
  fetchCurrentUser: flow17(function* () {
    const response = yield* toGenerator17(store.mestamasterApi.users.fetchCurrentUser());
    if (response.kind === "ok") {
      store.setAuthenticatedUser(response.data);
    } else {
      if (response.kind === "unauthorized") {
        yield store.logout();
      }
      console.error(`Error fetching current User: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((store) => ({
  login: flow17(function* (email, password) {
    const response = yield* toGenerator17(store.mestamasterApi.authentication.login({ email, password }));
    if (response.kind === "ok") {
      const { accessToken } = response.data;
      yield store.setApiAuthToken(accessToken);
      const user = yield store.fetchCurrentUser();
      store.connectCableConnection();
      return response;
    } else {
      console.error(`Error logging in: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  register: flow17(function* (email, password, firstName, lastName, gdprConsent) {
    const response = yield* toGenerator17(store.mestamasterApi.authentication.register({ email, password, firstName, lastName, gdprConsent }));
    if (response.kind === "ok") {
      if (!response.meta) {
        throw new Error("No meta in response");
      }
      const { accessToken } = response.meta;
      yield store.setApiAuthToken(accessToken);
      store.setAuthenticatedUser(response.data);
      return response;
    } else {
      console.error(`Error registering: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  requestPasswordlessSMSToken: flow17(function* (phoneNumber) {
    store.restAuthStore();
    const response = yield* toGenerator17(store.mestamasterApi.authentication.requestPasswordlessSMSToken(phoneNumber));
    if (response.kind === "ok") {
      return response;
    } else {
      console.error(`Error requesting passwordless token: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  loginWithPasswordless: flow17(function* (challenge, token) {
    const response = yield* toGenerator17(store.mestamasterApi.authentication.exchangePasswordlessToken(challenge, token));
    if (response.kind === "ok") {
      const { token: token2 } = response.data;
      yield store.setApiAuthToken(token2);
      return yield store.fetchCurrentUser();
    } else {
      console.error(`Error logging in with passwordless token: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  reloadCurrentUser: flow17(function* () {
    yield store.fetchCurrentUser();
    if (store.authenticatedUser) {
      yield store.authenticatedUser.loadTeamMemberships();
      yield store.authenticatedUser.fetchProjectMembershipInvites();
    }
  }),
  updateCurrentUser: flow17(function* (user) {
    const response = yield* toGenerator17(store.mestamasterApi.users.updateCurrentUser(user));
    if (response.kind === "ok" && store.authenticatedUser) {
      applyPartialSnapshot(store.authenticatedUser, response.data);
    } else {
      console.error(`Error updating current user: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  resetVerifier() {
    store.oauthCodeVerifier = generateCodeVerifier();
    return store.oauthCodeVerifier;
  },
  initiateSSO: flow17(function* (provider) {
    const code = generateCodeVerifier();
    store.oauthCodeVerifier = code;
    const response = yield* toGenerator17(store.mestamasterApi.authentication.initiateSSO(provider));
    if (response.kind === "ok") {
      store.ssoProviders.set(response.data.provider, response.data);
      return response;
    } else {
      console.error(`Error initiating SSO: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  handleSsoCallback: flow17(function* (provider, redirectUri, code) {
    if (!store.oauthCodeVerifier) {
      console.error("No code verifier found");
      return;
    }
    const response = yield* toGenerator17(store.mestamasterApi.authentication.oAuthTokenExchange(provider, redirectUri, code, store.oauthCodeVerifier));
    if (response.kind === "ok") {
      const { token } = response.data;
      store.setApiAuthToken(token);
      yield store.fetchCurrentUser();
      store.connectCableConnection();
    }
  })
}));

// src/data/stores/RootStore.ts
import { flow as flow18, getEnv as getEnv3, types as types30 } from "mobx-state-tree";
var RootStoreModel = types30.model("RootStore").props({
  usersStore: types30.optional(UsersStoreModel, {}),
  companiesStore: types30.optional(CompaniesStoreModel, {}),
  projectsStore: types30.optional(ProjectsStoreModel, {}),
  authenticationStore: types30.optional(AuthenticationStoreModel, {})
}).extend(withApi).actions((self) => ({
  initApi(config) {
    getEnv3(self).environment.setup(config);
  },
  reset() {
    self.usersStore = UsersStoreModel.create({});
    self.projectsStore = ProjectsStoreModel.create({});
    self.authenticationStore = AuthenticationStoreModel.create({});
  },
  softReset: flow18(function* () {
    self.usersStore = UsersStoreModel.create({});
    self.projectsStore = ProjectsStoreModel.create({});
    if (self.authenticationStore.isAuthenticated) {
      self.authenticationStore.reloadCurrentUser();
      self.projectsStore.fetchProjects();
    }
  }),
  setApiLocale(lang) {
    self.mestamasterApi.setApiLocale(lang);
  }
}));

// src/hooks/setupRootStore.ts
import { applySnapshot as applySnapshot19, onSnapshot } from "mobx-state-tree";

// src/services/storage/localStorageBackend/asyncStorage.ts
var asyncStorage_exports = {};
__export(asyncStorage_exports, {
  clear: () => clear,
  load: () => load,
  loadString: () => loadString,
  remove: () => remove,
  save: () => save,
  saveString: () => saveString
});
import AsyncStorage from "@react-native-async-storage/async-storage";
async function loadString(key) {
  try {
    return await AsyncStorage.getItem(key);
  } catch {
    return null;
  }
}
async function saveString(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
    return true;
  } catch {
    return false;
  }
}
async function load(key) {
  try {
    const result = await AsyncStorage.getItem(key);
    if (result === null) {
      return null;
    }
    return JSON.parse(result);
  } catch {
    return null;
  }
}
async function save(key, value) {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}
async function remove(key) {
  try {
    await AsyncStorage.removeItem(key);
  } catch {
  }
}
async function clear() {
  try {
    await AsyncStorage.clear();
  } catch {
  }
}

// src/hooks/setupRootStore.ts
var ROOT_STATE_STORAGE_KEY = "mestamaster-v1";
var _disposer;
async function setupRootStore(rootStore) {
  let restoredState;
  try {
    restoredState = await load(ROOT_STATE_STORAGE_KEY) || {};
    applySnapshot19(rootStore, restoredState);
  } catch (e) {
    await save(ROOT_STATE_STORAGE_KEY, {});
  }
  if (_disposer)
    _disposer();
  _disposer = onSnapshot(rootStore, (snapshot) => {
    save(ROOT_STATE_STORAGE_KEY, snapshot);
  });
  const unsubscribe = () => {
    _disposer();
  };
  return { rootStore, restoredState, unsubscribe };
}

// src/hooks/useStores.tsx
import { createContext, useContext, useEffect, useState } from "react";

// src/services/apis/mestamaster/mestamasterApi.ts
import {
  create
} from "apisauce";

// src/services/apis/apiProblem.ts
function getGeneralApiProblem(response) {
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", temporary: true };
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", temporary: true };
    case "TIMEOUT_ERROR":
      return { kind: "timeout", temporary: true };
    case "SERVER_ERROR":
      return { kind: "server", data: response.data };
    case "UNKNOWN_ERROR":
      return { kind: "unknown", temporary: true, data: response.data };
    case "CLIENT_ERROR":
      switch (response.status) {
        case 401:
          return { kind: "unauthorized", data: response.data };
        case 403:
          return { kind: "forbidden", data: response.data };
        case 404:
          return { kind: "not-found", data: response.data };
        default:
          return { kind: "rejected", data: response.data };
      }
    case "CANCEL_ERROR":
      return { kind: "unknown", temporary: true };
  }
  return { kind: "unknown", temporary: true };
}

// src/services/storage/secureStorage.ts
var secureStorage;
secureStorage = asyncStorage_exports;
var SecureStorageHandler = class {
  constructor() {
    this.strategy = asyncStorage_exports;
    import("./secureStorage-ZEBI4JWW.mjs").then((secureStorage2) => {
      return secureStorage2.isSecureStorageEnabled();
    }).then((isEnabled) => {
      if (isEnabled)
        this.strategy = secureStorage;
    }).catch(() => {
      console.debug("Falling back to asyncStorage");
    });
  }
  get(target, prop, receiver) {
    return Reflect.get(this.strategy, prop, receiver);
  }
};
var proxySecureStorage = new Proxy(asyncStorage_exports, new SecureStorageHandler());
var secureStorage_default = proxySecureStorage;

// src/services/apis/utils.ts
function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
}

// src/services/apis/mestamaster/cableConnection.ts
var CableConnection = class {
  constructor(url, token) {
    this.socket = null;
    this.callbacks = /* @__PURE__ */ new Map();
    this.latestPing = new Date();
    this.connectionReattempts = 0;
    this.REATTEMPT_INTERVAL = 5e3;
    this.MAX_REATTEMPTS = 2;
    this.connect(url, token);
  }
  connect(url, token) {
    this.socket = new WebSocket(url + "?token=" + token);
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data && data.identifier && data.message) {
        this.handleData(data.identifier, data.message);
      }
      if (data && data.type === "ping") {
        this.latestPing = new Date();
      }
    };
    this.socket.onopen = (event) => {
      this.connectionReattempts = 0;
      console.debug("Socket opened", event);
      this.resubscribe();
    };
    this.socket.onclose = (event) => {
      if (event.code === 1e3) {
        console.debug("Socket cleanly closed, will not reattempt", event);
        this.callbacks.clear();
        return;
      } else {
        if (this.connectionReattempts > this.MAX_REATTEMPTS) {
          console.debug(
            "Socket disconnected, could not reconnect after " + this.connectionReattempts + " attempts. Will not reattempt",
            event
          );
          this.callbacks.clear();
          return;
        }
        console.debug(
          "Socket disconnected, will reattempt (current " + this.connectionReattempts + ")",
          event
        );
        setTimeout(() => {
          this.connect(url, token);
        }, this.REATTEMPT_INTERVAL);
        this.connectionReattempts += 1;
      }
    };
    this.socket.onerror = (event) => {
      console.debug("Socket got an error", event);
      if (this.connectionReattempts > this.MAX_REATTEMPTS) {
        return;
      } else {
        setTimeout(() => {
          this.connect(url, token);
        }, this.REATTEMPT_INTERVAL);
      }
      this.connectionReattempts += 1;
    };
  }
  disconnect() {
    var _a;
    (_a = this.socket) == null ? void 0 : _a.close(1e3, "Logout disconnect");
  }
  resubscribe() {
    Array.from(this.callbacks.entries() || []).forEach(([identifier, _callbacks]) => {
      console.debug("Reconnecting to " + identifier);
      if (this.socket) {
        this.socket.send(JSON.stringify({ command: "subscribe", identifier }));
      }
    });
  }
  subscribe(channel, parameters, callback, onSubscriptionConfirmed) {
    const existingSubscription = Array.from(this.callbacks.keys()).find(
      (key) => key === JSON.stringify({ channel, ...parameters })
    );
    if (existingSubscription) {
      console.info("Already subscribed to " + existingSubscription + " - updating callback");
      this.attachCallback(existingSubscription, callback);
      return;
    }
    if (this.socket && this.socket.readyState !== this.socket.OPEN) {
      this.socket.onopen = () => {
        this.subscribe(channel, parameters, callback);
        console.debug("Socket (delayed) subscription");
      };
      return;
    }
    const identifier = JSON.stringify({ channel, ...parameters });
    if (this.socket) {
      this.socket.send(JSON.stringify({ command: "subscribe", identifier }));
      this.attachCallback(identifier, callback);
      if (onSubscriptionConfirmed) {
        onSubscriptionConfirmed(this.socket);
      }
    }
  }
  attachCallback(identifier, callback) {
    this.callbacks.set(identifier, [callback]);
  }
  handleData(identifier, data) {
    var _a;
    (_a = this.callbacks.get(identifier)) == null ? void 0 : _a.forEach((callback) => callback(data));
  }
};

// src/services/apis/mestamaster/modules/usersApi.ts
var UsersApiModule = class {
  constructor(mestaMasterApi) {
    this.mestaMasterApi = mestaMasterApi;
  }
  async getUser(userId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/users/" + userId
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchCurrentUser() {
    const response = await this.mestaMasterApi.apisauce.get("api/v3/user");
    return this.mestaMasterApi.handleResponse(response);
  }
  async updateCurrentUser(user) {
    const response = await this.mestaMasterApi.apisauce.patch(
      "api/v3/user",
      user
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchProjectUsers(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/users",
      { items: 1e4 }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/restModule.ts
var RestModule = class {
  constructor(endpoint, api) {
    this.endpoint = endpoint;
    this.api = api;
  }
  async get(id) {
    const response = await this.api.apisauce.get(`${this.endpoint}/${id}`);
    return this.api.handleResponse(response);
  }
  async list(items, page) {
    const response = await this.api.apisauce.get(
      this.endpoint,
      { items: items || 1e4, page: page || 1 }
    );
    return this.api.handleResponse(response);
  }
  async create(data) {
    const response = await this.api.apisauce.post(this.endpoint, data);
    return this.api.handleResponse(response);
  }
  async update(id, data) {
    const response = await this.api.apisauce.put(`${this.endpoint}/${id}`, data);
    return this.api.handleResponse(response);
  }
  async partialUpdate(id, data) {
    const response = await this.api.apisauce.patch(`${this.endpoint}/${id}`, data);
    return this.api.handleResponse(response);
  }
  async delete(id) {
    const response = await this.api.apisauce.delete(`${this.endpoint}/${id}`);
    return this.api.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/companiesApi.ts
var CompaniesApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/companies", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
  async getCompanies() {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/companies",
      { items: 1e3 }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async getCompany(id) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/companies/" + id
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async getCompanyMemberships(companyId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/companies/" + companyId + "/company_memberships",
      { items: 1e3 }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/projectsApi.ts
var ProjectsApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/projects", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
  async getProject(id) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + id
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async createProject(companyId, newProject) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/companies/" + companyId + "/projects",
      newProject
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async updateProject(projectId, updatedObject) {
    const response = await this.mestaMasterApi.apisauce.patch(
      "api/v3/projects/" + projectId,
      updatedObject
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async getProjectAnalytics(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/basic_analytics"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/authenticationApi.ts
var AuthenticationApiModule = class {
  constructor(mestaMasterApi) {
    this.mestaMasterApi = mestaMasterApi;
  }
  async login(data) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v2/login",
      data
    );
    if (response.ok) {
      const newToken = response.data.authToken;
      this.mestaMasterApi.apisauce.setHeaders({
        Authorization: "Token " + newToken
      });
    }
    return this.mestaMasterApi.handleResponse(response);
  }
  async register(data) {
    const response = await this.mestaMasterApi.apisauce.post("api/v3/user", data);
    return this.mestaMasterApi.handleResponse(response);
  }
  async requestPasswordlessSMSToken(phoneNumber) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/passwordless/request/phone",
      { phoneNumber }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async exchangePasswordlessToken(challenge, token) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/passwordless/redeem",
      { challenge, token }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async initiateSSO(email) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/sso/initiate",
      { email }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async oAuthTokenExchange(provider, redirectUri, code, oauthCodeVerifier) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/sso/exchange/" + provider,
      { oauthCode: code, oauthCodeVerifier, redirectUri }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/projectMembershipsApi.ts
var ProjectMembershipsApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/project_memberships", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
  async fetchProjectMemberships(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/project_memberships"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchProjectMembershipRequests(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/project_membership_requests"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async approveProjectMembershipRequest(requestId, role) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_requests/" + requestId + "/approve"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async rejectProjectMembershipRequest(requestId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_requests/" + requestId + "/reject"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async createProjectMembershipRequest(code) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_requests",
      { projectCode: code }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchProjectMembershipInvites(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/project_membership_invites"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchCurrentUserProjectMembershipInvites() {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/user/project_membership_invites"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async acceptProjectMembershipInvite(inviteId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_invites/" + inviteId + "/accept"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async rejectProjectMembershipInvite(inviteId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_invites/" + inviteId + "/reject"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async resendProjectMembershipInvite(inviteId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_invites/" + inviteId + "/resend"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async inviteToProject(params) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/projects/" + params.projectId + "/project_membership_invites",
      { ...params }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async updateProjectMembershipInvite(id, data) {
    const response = await this.mestaMasterApi.apisauce.put(
      "api/v3/project_membership_invites/" + id,
      data
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/companyMembershipsApi.ts
var CompanyMemershipsApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/company_memberships", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
};

// src/services/apis/mestamaster/mestamasterApi.ts
var DEFAULT_API_CONFIG = {
  url: "https://api.mestamaster.fi",
  wsUrl: "wss://api.mestamaster.fi",
  timeout: 1e4,
  onErrorCallback: (error) => {
    console.error("Error in API", error);
  }
};
var MestamasterApi = class {
  constructor(config = DEFAULT_API_CONFIG) {
    this.handleResponse = (response) => {
      if (!response.ok || response.data === void 0) {
        let problem = getGeneralApiProblem(response);
        if (!problem) {
          problem = { kind: "unknown", temporary: true };
        }
        if (this.config.onErrorCallback) {
          this.config.onErrorCallback(problem);
        }
        return problem;
      }
      if (response.status === 204) {
        return { kind: "ok", data: {}, meta: {} };
      }
      try {
        const data = response.data.data;
        return { kind: "ok", data, meta: response.data.meta };
      } catch (e) {
        console.log("error handle response", e, response);
        return { kind: "bad-data" };
      }
    };
    this.users = new UsersApiModule(this);
    this.companies = new CompaniesApiModule(this);
    this.projects = new ProjectsApiModule(this);
    this.authentication = new AuthenticationApiModule(this);
    this.projectMemberships = new ProjectMembershipsApiModule(this);
    this.companyMemberships = new CompanyMemershipsApiModule(this);
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json"
      }
    });
    this.apisauce.axiosInstance.interceptors.request.use(
      async (config2) => {
        let token = await secureStorage_default.loadString("MESTAMASTER_TOKEN");
        if (token) {
          config2.headers.Authorization = `Token ${token}`;
        }
        return config2;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    this.apisauce.addMonitor(this.tokenMonitor);
  }
  tokenMonitor(response) {
    const { ok, status } = response;
    if (!ok && status === 401) {
      secureStorage_default.remove("MESTAMASTER_TOKEN");
    }
  }
  setApiLocale(lang) {
    this.apisauce.setHeaders({
      "Accept-Language": lang
    });
  }
  async hasValidAuthToken() {
    const token = await secureStorage_default.loadString("MESTAMASTER_TOKEN");
    if (token) {
      return true;
    }
    return false;
  }
  async setAuthToken(token) {
    if (!token)
      return await this.resetAuthToken();
    this.apisauce.setHeader("Authorization", "Token " + token);
    return await secureStorage_default.saveString("MESTAMASTER_TOKEN", token);
  }
  async resetAuthToken() {
    this.apisauce.setHeader("Authorization", "");
    return await secureStorage_default.remove("MESTAMASTER_TOKEN");
  }
  async createProjectStandaloneTodo(projectId, newTodo) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/todos",
      newTodo
    );
    return this.handleResponse(response);
  }
  async getProjectTodos(project_id, filters) {
    let params = { items: filters.items };
    if (filters.userId) {
      params["useId"] = filters.userId;
    }
    if (filters.before) {
      params["endsBefore"] = filters.before.toISOString();
    }
    if (filters.after) {
      params["startsAfter"] = filters.after.toISOString();
    }
    if (filters.complete != void 0) {
      params["complete"] = filters.complete;
    }
    if (filters.own != void 0) {
      params["own"] = filters.own;
    }
    if (filters.updatedAfter) {
      params["updatedAfter"] = filters.updatedAfter.toISOString();
    }
    if (filters.todoIds) {
      params["todoIds"] = filters.todoIds;
    }
    if (filters.cursor) {
      params["afterCursor"] = filters.cursor;
    }
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/todos",
      params,
      {
        headers: {
          "MM-Pagination-Mode": "cursor"
        }
      }
    );
    return this.handleResponse(response);
  }
  async getTaktTrainTodos(taktTrainId) {
    const response = await this.apisauce.get(
      "api/v3/takt/takt_trains/" + taktTrainId + "/todos"
    );
    return this.handleResponse(response);
  }
  async getTodoDetails(todoId) {
    const response = await this.apisauce.get(
      "api/v3/todos/" + todoId
    );
    return this.handleResponse(response);
  }
  async getTodoEvents(todo_id) {
    var _a, _b, _c;
    const response = await this.apisauce.get(
      "api/v3/todos/" + todo_id + "/todo_events"
    );
    const result = this.handleResponse(response);
    if ("data" in result) {
      return {
        ...result,
        data: result.data,
        meta: {
          pagination: {
            page: ((_a = result.meta) == null ? void 0 : _a.page) || 0,
            items: ((_b = result.meta) == null ? void 0 : _b.items) || 0,
            total: ((_c = result.meta) == null ? void 0 : _c.total) || 0
          }
        }
      };
    }
    return result;
  }
  async getTodoInterruptions(todo_id) {
    const response = await this.apisauce.get(
      "api/v3/todos/" + todo_id + "/interruptions"
    );
    return this.handleResponse(response);
  }
  async getTodoComments(todo_id) {
    const response = await this.apisauce.get(
      "api/v3/todos/" + todo_id + "/comments"
    );
    return this.handleResponse(response);
  }
  async getProjectTeams(project_id, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/teams",
      params
    );
    return this.handleResponse(response);
  }
  async getTeamsAnalytics(project_id) {
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/analytics/teams"
    );
    return this.handleResponse(response);
  }
  async createTeam(projectId, data) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/teams",
      data
    );
    return this.handleResponse(response);
  }
  async updateTeam(teamId, data) {
    const response = await this.apisauce.put(
      "api/v3/teams/" + teamId,
      data
    );
    return this.handleResponse(response);
  }
  async deleteTeam(teamId) {
    const response = await this.apisauce.delete(
      "api/v3/teams/" + teamId
    );
    return this.handleResponse(response);
  }
  async getProjectTakts(project_id, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/takt/takts",
      params
    );
    return this.handleResponse(response);
  }
  async emitTodoEvent(todoId, event) {
    const response = await this.apisauce.patch(
      "api/v3/todos/" + todoId + "/" + event
    );
    return this.handleResponse(response);
  }
  async createTodoInterruption(todoId, comment, reason) {
    const response = await this.apisauce.post(
      "api/v3/todos/" + todoId + "/interruptions",
      { comment, reason }
    );
    return this.handleResponse(response);
  }
  async createTodoComment(todoId, comment, files) {
    const formData = new FormData();
    formData.append("content", comment);
    if (files) {
      files.forEach((file) => {
        const blob = DataURIToBlob(file.uri);
        formData.append("attachments[]", blob, file.name);
      });
    }
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const response = await this.apisauce.post(
      "api/v3/todos/" + todoId + "/comments",
      formData,
      { headers }
    );
    return this.handleResponse(response);
  }
  async fetchUserTeamMemberships(userId) {
    const response = await this.apisauce.get(
      "api/v3/users/" + userId + "/team_memberships"
    );
    return this.handleResponse(response);
  }
  async createDevice(devicePlatform, expoToken, deviceToken) {
    const response = await this.apisauce.post(
      "api/v3/user/devices",
      {
        devicePlatform,
        expoToken,
        deviceToken
      }
    );
    return this.handleResponse(response);
  }
  async destroyDevice(deviceId) {
    const response = await this.apisauce.delete(
      "api/v3/users/devices/" + deviceId
    );
    return this.handleResponse(response);
  }
  async checkDeletedTodos(todoIds) {
    const response = await this.apisauce.post(
      "api/v3/todos/check_deleted",
      { todoIds }
    );
    return this.handleResponse(response);
  }
  async getTaktTrains(projectId, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + projectId + "/takt/takt_trains",
      params
    );
    return this.handleResponse(response);
  }
  async completeChecklistItem(id) {
    const response = await this.apisauce.patch(
      "api/v3/todos/checklist_items/" + id + "/complete"
    );
    return this.handleResponse(response);
  }
  async undoChecklistItem(id) {
    const response = await this.apisauce.patch(
      "api/v3/todos/checklist_items/" + id + "/undo"
    );
    return this.handleResponse(response);
  }
  async getProjectSpaces(project_id, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/spaces",
      params
    );
    return this.handleResponse(response);
  }
  async createProjectSpace(projectId, newSpace) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/spaces",
      newSpace
    );
    return this.handleResponse(response);
  }
  async updateSpace(spaceId, updatedSpace) {
    const response = await this.apisauce.put(
      "api/v3/spaces/" + spaceId,
      updatedSpace
    );
    return this.handleResponse(response);
  }
  async deleteSpace(spaceId) {
    const response = await this.apisauce.delete(
      "api/v3/spaces/" + spaceId
    );
    return this.handleResponse(response);
  }
  async getTaktTrainTemplates(projectId) {
    const response = await this.apisauce.get(
      "api/v3/projects/" + projectId + "/takt_train_templates"
    );
    return this.handleResponse(response);
  }
  async getTaktTrainsByTemplateId(taktTrainTemplateId) {
    const response = await this.apisauce.get(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/takt_trains"
    );
    return this.handleResponse(response);
  }
  async createTaktTrainTemplate(projectId, newTaktTrainTemplate) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/takt_train_templates",
      newTaktTrainTemplate
    );
    return this.handleResponse(response);
  }
  async updateTaktTrainTemplate(taktTrainTemplateId, updatedTaktTrainTemplate) {
    const response = await this.apisauce.put(
      "api/v3/takt_train_templates/" + taktTrainTemplateId,
      updatedTaktTrainTemplate
    );
    return this.handleResponse(response);
  }
  async deleteTaktTrainTemplate(taktTrainTemplateId) {
    const response = await this.apisauce.delete(
      "api/v3/takt_train_templates/" + taktTrainTemplateId
    );
    return this.handleResponse(response);
  }
  async cloneTaktTrainTemplate(taktTrainTemplateId, clonedTrainData) {
    const response = await this.apisauce.post(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/fork",
      clonedTrainData
    );
    return this.handleResponse(response);
  }
  async getTaktWagonTemplates(taktTrainTemplateId) {
    const response = await this.apisauce.get(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/takt_wagon_templates/"
    );
    return this.handleResponse(response);
  }
  async createTaktWagonTemplate(taktTrainTemplateId, newTaktWagonTemplate) {
    const response = await this.apisauce.post(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/takt_wagon_templates",
      newTaktWagonTemplate
    );
    return this.handleResponse(response);
  }
  async updateTaktWagonTemplate(taktWagonTemplateId, updatedTaktWagonTemplate) {
    const response = await this.apisauce.put(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId,
      updatedTaktWagonTemplate
    );
    return this.handleResponse(response);
  }
  async deleteTaktWagonTemplate(taktWagonTemplateId) {
    const response = await this.apisauce.delete(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId
    );
    return this.handleResponse(response);
  }
  async getTaskTemplates(taktWagonTemplateId) {
    const response = await this.apisauce.get(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId + "/task_templates/"
    );
    return this.handleResponse(response);
  }
  async createTaskTemplate(taktWagonTemplateId, newTaskTemplate) {
    const response = await this.apisauce.post(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId + "/task_templates/",
      newTaskTemplate
    );
    return this.handleResponse(response);
  }
  async updateTaskTemplate(taskTemplateId, updatedTaskTemplate) {
    const response = await this.apisauce.put(
      "api/v3/task_templates/" + taskTemplateId,
      updatedTaskTemplate
    );
    return this.handleResponse(response);
  }
  async deleteTaskTemplate(taskTemplateId) {
    const response = await this.apisauce.delete(
      "api/v3/task_templates/" + taskTemplateId
    );
    return this.handleResponse(response);
  }
  async updateTaktTrain(taktTrainId, updatedTaktTrain) {
    const response = await this.apisauce.patch(
      "api/v3/takt/takt_trains/" + taktTrainId,
      updatedTaktTrain
    );
    return this.handleResponse(response);
  }
  async createTaktTrain(projectId, newTaktTrain) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/takt/takt_trains",
      newTaktTrain
    );
    return this.handleResponse(response);
  }
  async deleteTaktTrain(taktTrainId) {
    const response = await this.apisauce.delete(
      "api/v3/takt/takt_trains/" + taktTrainId
    );
    return this.handleResponse(response);
  }
  async getCableSubscription() {
    const token = await secureStorage_default.loadString("MESTAMASTER_TOKEN") || "";
    if (token === "") {
      throw new Error("Cannot connect while unauthenticated.");
    }
    return new CableConnection(this.config.wsUrl + "/cable", token);
  }
};

// src/data/environment.ts
var Environment = class {
  constructor(envConfig) {
    this.mestamasterApi = new MestamasterApi(envConfig);
    this.cableConnection = null;
    this.connectCableConnection();
  }
  connectCableConnection() {
    var _a, _b, _c, _d;
    if (this.cableConnection) {
      console.debug("Cable connection already exists");
      if (((_b = (_a = this.cableConnection) == null ? void 0 : _a.socket) == null ? void 0 : _b.readyState) === WebSocket.OPEN || ((_d = (_c = this.cableConnection) == null ? void 0 : _c.socket) == null ? void 0 : _d.readyState) === WebSocket.CONNECTING) {
        console.debug("Cable connection already open or connecting");
        return;
      }
      console.debug("Cable connection closed, reconnecting");
      this.disconnectCableConnection();
    }
    this.mestamasterApi.getCableSubscription().then((cableConnection) => {
      var _a2;
      this.cableConnection = cableConnection;
      if (!((_a2 = this.cableConnection) == null ? void 0 : _a2.socket)) {
        console.error("Cable connection not available");
        return;
      }
      this.cableConnection.socket.onopen = () => {
        console.debug("Cable connection opened");
      };
    }).catch((error) => {
      console.debug("Could not connect to websocket", error);
    });
  }
  disconnectCableConnection() {
    var _a;
    console.debug("Disconnecting cable connection");
    (_a = this.cableConnection) == null ? void 0 : _a.disconnect();
    this.cableConnection = null;
  }
};

// src/hooks/useStores.tsx
import { jsx } from "react/jsx-runtime";
var RootStoreContext = createContext(void 0);
function DataPlumberProvider({ envConfig, value, children }) {
  const env = envConfig ? new Environment(envConfig) : null;
  const [currentRoot, setCurrentRoot] = useState(value || RootStoreModel.create({}, env));
  return /* @__PURE__ */ jsx(RootStoreContext.Provider, {
    value: currentRoot,
    children
  });
}
function useStores() {
  const context = useContext(RootStoreContext);
  if (context === void 0) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }
  return context;
}
var useInitialRootStore = (callback) => {
  const rootStore = useStores();
  const [rehydrated, setRehydrated] = useState(false);
  useEffect(() => {
    let _unsubscribe;
    (async () => {
      const { restoredState, unsubscribe } = await setupRootStore(rootStore);
      _unsubscribe = unsubscribe;
      setRehydrated(true);
      if (callback)
        callback();
    })();
    return () => {
      if (_unsubscribe)
        _unsubscribe();
    };
  }, []);
  return { rootStore, rehydrated };
};

// src/hooks/getRootStore.ts
import { getRoot as getRoot2 } from "mobx-state-tree";
var getRootStore = (self) => {
  return getRoot2(self);
};

// src/utils/misc.ts
var snakeToCamel = (str) => str.toLowerCase().replace(
  /([-_][a-z])/g,
  (group) => group.toUpperCase().replace("-", "").replace("_", "")
);

// src/utils/translationUtils.ts
var interruptionReasonTranslationKey = (interruption) => {
  return "dataPlumber:models.interruption.reasons." + snakeToCamel(interruption.reason);
};
var todoEventTranslationKey = (todoEvent) => {
  return "dataPlumber:models.todoEvent." + snakeToCamel(todoEvent.event);
};
var todoTranslationKey = (todo) => {
  return "dataPlumber:models.todos.statuses." + snakeToCamel(todo.state);
};

// src/i18n/shared-tranlsations/en.json
var en_default = {
  models: {
    interruption: {
      interrupt: "Interrupt",
      reason: "Reason",
      reasons: {
        equipments: "Equipments",
        materials: "Materials",
        necessities: "Supplies",
        notDefined: "Not defined",
        other: "Other",
        plans: "Plans",
        prevTodo: "Previous todo",
        spaceNotAvailable: "Space not available",
        spaceNotClean: "Space not clean"
      },
      taskInterruptedBy: "Task interrupted by {{name}}"
    },
    todo: {
      note: "Note",
      blocked: "Blocked",
      description: "Description",
      late: "Late",
      scheduled: "Scheduled",
      upcoming: "Upcoming"
    },
    todoEvent: {
      completed: "Completed",
      continued: "Undone",
      resolved: "Resolved",
      started: "Started",
      cancelled: "Cancelled",
      skipped: "Skipped",
      unskipped: "Unskipped",
      interrupted: "Interrupted"
    },
    todos: {
      statuses: {
        completed: "Completed",
        disabled: "Disabled",
        enabled: "Enabled",
        interrupted: "Interrupted",
        skipped: "Skipped",
        started: "Started",
        waiting: "Waiting"
      }
    }
  }
};

// src/i18n/shared-tranlsations/fi.json
var fi_default = {
  models: {
    interruption: {
      interrupt: "Keskeyt\xE4",
      reason: "Syy",
      reasons: {
        equipments: "Kalusto ja ty\xF6koneet",
        materials: "Materiaalit",
        necessities: "Tarvikkeet",
        notDefined: "Ei m\xE4\xE4ritelty",
        other: "Muu syy",
        plans: "Suunnitelmat",
        prevTodo: "Edellinen ty\xF6vaihe",
        spaceNotAvailable: "Mesta ei ole vapaa",
        spaceNotClean: "Mesta ei ole siisti"
      },
      taskInterruptedBy: "{{name}} keskeytti teht\xE4v\xE4n"
    },
    todo: {
      note: "Huomio",
      blocked: "Keskeytetty",
      description: "Kuvaus",
      late: "My\xF6h\xE4ss\xE4",
      scheduled: "Suunniteltu",
      upcoming: "Tulossa"
    },
    todoEvent: {
      completed: "Valmis",
      continued: "Kumottu",
      resolved: "Ratkaistu",
      started: "Aloitettu",
      cancelled: "Peruttu",
      skipped: "Ohitettu",
      unskipped: "Ei ohitettu",
      interrupted: "Keskeytetty"
    },
    todos: {
      statuses: {
        completed: "Valmis",
        disabled: "Ei tekij\xE4\xE4",
        enabled: "Voi aloittaa",
        interrupted: "Keskeytetty",
        skipped: "Ei tehd\xE4",
        started: "Ty\xF6n alla",
        waiting: "Ei saa aloittaa"
      }
    }
  }
};

// src/i18n/index.ts
var sharedTranslations = {
  en: en_default,
  fi: fi_default
};
export {
  DataPlumberProvider,
  clear,
  generateCodeChallenge,
  generateCodeVerifier,
  getInitiationPKCEUrl,
  getRootStore,
  interruptionReasonTranslationKey,
  load,
  loadString,
  asyncStorage_exports as localStorage,
  models_exports as models,
  remove,
  save,
  saveString,
  setupRootStore,
  sharedTranslations,
  stores_exports as stores,
  todoEventTranslationKey,
  todoTranslationKey,
  useInitialRootStore,
  useStores
};
