import { useRef } from 'react';
import { useTheme } from '@/hooks';
import { cn } from '@/utils';
import { TextField as PlasterTextField, TextFieldProps as PlasterTextFieldProps } from 'plaster-ui';

interface TextFieldProps extends PlasterTextFieldProps {
  /**
   * (Optional) Trigger callback on Enter/Return key press.
   */
  onEnter?: () => void;
  /**
   * (Optional) Custom classnames.
   */
  className?: string;
}

export const TextField: React.FC<TextFieldProps> = ({
  onEnter,
  className,
  label,
  helper,
  ...props
}) => {
  const { isDarkMode } = useTheme();
  const input = useRef(null);

  return (
    <div
      className={cn(
        '[&_input]:font-mono',
        '[&_input]:!text-base',
        '[&_input]:!text-foreground',
        '[&_input:focus]:outline-none',
        className
      )}
      data-testid='text-field'
    >
      <PlasterTextField
        ref={input}
        darkMode={isDarkMode}
        onSubmitEditing={onEnter}
        labelComponent={
          <div className='text-foreground font-sans font-bold' data-testid='text-field-label'>
            {label}
          </div>
        }
        helperComponent={
          <div className='text-foreground font-sans text-red-500' data-testid='text-field-helper'>
            {helper}
          </div>
        }
        {...props}
      />
    </div>
  );
};
