import { ErrorBoundary as ErrorBoundaryComponent, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ErrorTemplate } from '@atoms';

type ErrorFallback = {
  children: React.ReactNode;
};

const ErrorFallbackComponent: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <ErrorTemplate
      errorTitle={`${t('error.general.title')} \u{1f972}`}
      errorSubtitle={t('error.general.subtitle')}
      actionBtnText={t('reload')}
      onActionBtnClick={ ()=> {
        // reload page
        resetErrorBoundary();
        window.location.reload();
      }}
    />
  );
};

export const ErrorBoundary: React.FC<ErrorFallback> = ({ children }) => (
  <ErrorBoundaryComponent FallbackComponent={ErrorFallbackComponent}>
    {children}
  </ErrorBoundaryComponent>
);
