import { useContext } from 'react';
import { ModalProviderContext } from '@/contexts';

export const useModal = () => {
  const context = useContext(ModalProviderContext);

  if (context === undefined)
    throw new Error('useModal must be used within a ModalProvider');

  return context;
};
