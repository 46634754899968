import { useState, useEffect, useRef } from 'react';

export const useClickedOutside = () => {
  const [isClickedOutside, setIsClickedOutside] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsClickedOutside(true);
    }
  };

  const resetClickedOutsideState = () => {
    setIsClickedOutside(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return { ref, isClickedOutside, resetClickedOutsideState };
};
