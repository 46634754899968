import { useContext } from 'react';
import { TaktTrainTemplateDnDContext } from '@/contexts';

export const useTaktTrainTemplateDnD = () => {
  const context = useContext(TaktTrainTemplateDnDContext);

  if (context === undefined)
    throw new Error('useTaktTrainTemplateDnD must be used within a TaktTrainTemplateDnDProvider');

  return context;
};
