import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

type ModalType = {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modal?: boolean;
  closeDisabled?: boolean;
};

export const Modal: React.FC<ModalType> = ({
  show,
  onClose,
  children,
  modal,
  closeDisabled = false,
}) => {
  return (
    <Dialog.Root open={show} modal={modal}>
      <Dialog.Portal container={document.getElementById('modal')}>
        <Dialog.Overlay className='fixed inset-0 z-[100] bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0' />
        <Dialog.Content
          onEscapeKeyDown={closeDisabled ? undefined : onClose}
          onPointerDownOutside={closeDisabled ? undefined : onClose}
          className='fixed text-base left-[50%] top-[50%] z-[100] grid w-fit max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border-2 bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]'
        >
          {children}
          {!closeDisabled && (
            <div
              role='button'
              onClick={onClose}
              className='absolute right-4 top-4 opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
            >
              <Cross2Icon className='h-4 w-4' />
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const ModalTitle: React.FC<Partial<ModalType>> = ({ children }) => (
  <Dialog.Title className='text-xl font-heading leading-none tracking-tight'>
    {children}
  </Dialog.Title>
);

export const ModalFooter: React.FC<Partial<ModalType>> = ({ children }) => (
  <div className={'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2'}>{children}</div>
);
