import { createContext, useState } from 'react';
import { Modal, ModalTitle, ModalFooter } from '@atoms';

type ModalProviderProps = {
  children: React.ReactNode;
};

type ShowModalParamsType = {
  title?: string;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  closeDisabled?: boolean;
};

type ModalProviderState = {
  showModal: ({ title, content, footer }: ShowModalParamsType) => void;
  hideModal: () => void;
};

const initialState: ModalProviderState = {
  showModal: () => null,
  hideModal: () => null,
};

export const ModalProviderContext = createContext<ModalProviderState>(initialState);

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [title, setTitle] = useState('');
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [footerContent, setFooterContent] = useState<React.ReactNode>(null);
  const [closeDisabled, setDisabledClose] = useState(false);

  const showModal = ({ title, content, footer, closeDisabled }: ShowModalParamsType) => {
    setTitle(title || '');
    setModalContent(content || null);
    setFooterContent(footer || null);
    setModalVisibility(true);
    setDisabledClose(closeDisabled || false);
  };

  const hideModal = () => {
    setModalVisibility(false);
  };

  return (
    <ModalProviderContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal show={modalVisibility} onClose={hideModal} closeDisabled={closeDisabled}>
        {title && <ModalTitle>{title}</ModalTitle>}
        {modalContent}
        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </Modal>
    </ModalProviderContext.Provider>
  );
};
