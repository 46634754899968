/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Custom API-fetching hook inspired by:
 * - https://usehooks-ts.com/react-hook/use-fetch
 * - https://stackoverflow.com/questions/67221698/typescript-infer-arguments-of-a-callback-function-passed-as-a-parameter-to-anot
 */
import { useEffect, useCallback, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/hooks';
import { GeneralApiProblem, MestamasterApiResult, MestamasterApiListResult } from 'data-plumber';

// API state type
interface State<K> {
  status?: 'loading' | 'fetched' | 'error';
  data?: K;
  error?: Error;
}

// Reducer's action type
type Action =
  | { type: 'loading' }
  | { type: 'fetched'; payload: any }
  | { type: 'error'; payload: Error }
  | { type: 'reset' };

// Hook type
type UseApiCallbackType<T extends unknown[], K> = State<K> & {
  call: (...args: T) => void;
  clear: () => void;
};

/**
 * Custom hook for managing API requests
 * that automatically pop-ups toasters upon error
 * @param apiCallback - API method imported from `data-plumber` package
 * @returns An object contains:
 * - `call`: Method to invoke the API request (with type-checking support)
 * - `status`: Showing the current status of the API request
 *   (`loading`, `fetched`, `error`)
 * - `data`: Data returned from the API
 * - `error`: An `Error` object contains error message.
 * - `clear`: Method to clear the fetched data and reset the state.
 */
export const useApiCallback = <T extends unknown[], K>(
  apiCallback: (
    ...args: T
  ) => Promise<GeneralApiProblem | MestamasterApiResult<K> | MestamasterApiListResult<K>>
): UseApiCallbackType<T, K> => {
  const { t } = useTranslation();
  const { toast } = useToast();

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef(false);
  useEffect(() => {
    cancelRequest.current = false;
    return () => {
      cancelRequest.current = true;
    };
  }, []);

  // Initial state
  const initialState: State<K> = {
    status: undefined,
    error: undefined,
    data: undefined,
  };

  // Reducer
  const apiCallbackReducer = (state: State<K>, action: Action): State<K> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, status: 'loading' };
      case 'fetched':
        return { ...initialState, data: action.payload, status: 'fetched' };
      case 'error':
        return { ...initialState, error: action.payload, status: 'error' };
      case 'reset':
        return initialState;
      default:
        return state;
    }
  };

  // Initialize reducer
  const [state, dispatch] = useReducer(apiCallbackReducer, initialState);

  // Show toast upon error
  useEffect(() => {
    if (state.error) {
      toast({
        title: t('error.title'),
        variant: 'destructive',
        description: state.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error]);

  // Method for invoking callback function
  const call = useCallback(
    async (...args: Parameters<typeof apiCallback>) => {
      if (!apiCallback) return;
      dispatch({ type: 'loading' });
      try {
        const response = await apiCallback(...args);
        // Throw an error if response kind is not "ok"
        if (response.kind !== 'ok') {
          throw new Error(response.data.data?.message || response.data.data?.msg);
        }
        if (cancelRequest.current) return;
        dispatch({ type: 'fetched', payload: response.data });
      } catch (error) {
        if (cancelRequest.current) return;
        console.error('Error fetching data:', error);
        dispatch({ type: 'error', payload: error as Error });
      }
    },
    [apiCallback]
  );

  // Method for reseting the state
  const clear = () => {
    dispatch({ type: 'reset' });
  };

  return { call, ...state, clear };
};
