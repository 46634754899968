import { memo } from 'react';
import { models } from 'data-plumber';
import { useTranslation } from 'react-i18next';
import { Controller, type Control, type FieldErrors } from 'react-hook-form';
import { cn, convertMinToHours } from '@/utils';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@atoms';
import { DotsVerticalIcon, TrashIcon, InputIcon, ClockIcon } from '@radix-ui/react-icons';
import { TaskCardAddEditForm } from './TaskCardAddEditForm';
import { TeamSelector } from './TeamSelector';

type TaskTemplateUpdateForm = Pick<models.ITaskTemplate, 'name' | 'teamId'>;

export type TaskTemplateCardType = {
  taskTemplate: models.ITaskTemplate;
  displayTaskName?: string;
  pendingCard?: boolean;
  renameMode?: boolean;
  collapsed?: boolean;
  isDragging?: boolean;
  isUpdating?: boolean;
  formControl?: Control<TaskTemplateUpdateForm>;
  formErrors?: FieldErrors<TaskTemplateUpdateForm>;
  className?: string;
  onDelete?: () => void;
  onRename?: () => void;
  onRenameSubmit?: () => void;
  onRenameCancel?: () => void;
};

export const TaskCard: React.FC<TaskTemplateCardType> = memo(
  ({
    taskTemplate,
    displayTaskName,
    pendingCard = false,
    renameMode = false,
    collapsed = false,
    isDragging = false,
    isUpdating = false,
    formControl,
    formErrors,
    className,
    onDelete,
    onRename,
    onRenameSubmit,
    onRenameCancel,
  }) => {
    const { t } = useTranslation();

    const handleRenameSubmit = () => {
      if (onRenameSubmit) onRenameSubmit();
    };

    const handleRenameCancel = () => {
      if (onRenameCancel) onRenameCancel();
    };

    return renameMode && !collapsed ? (
      <Controller
        name='name'
        control={formControl}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <TaskCardAddEditForm
            taskId={taskTemplate.id}
            taskName={value}
            onChange={onChange}
            onSubmit={handleRenameSubmit}
            onCancel={handleRenameCancel}
            fieldError={formErrors?.name}
          />
        )}
      />
    ) : (
      <div
        className={cn(
          'bg-background dark:bg-gray-800 my-2',
          'first:mt-0 border-2 border-slate-300 last:mb-0 active:cursor-grabbing',
          renameMode && 'pb-0',
          isDragging && 'border-2 border-dashed border-slate-100 bg-slate-400',
          (isUpdating || pendingCard) && 'opacity-50 select-none pointer-events-none',
          className
        )}
      >
        <div
          className={cn(
            collapsed ? 'flex flex-col' : 'flex flex-row',
            isDragging && 'invisible opacity-50 bg-slate-500'
          )}
        >
          <div
            className={cn(
              'transition-colors duration-200 bg-defaultTeamColor',
              collapsed ? 'h-2.5 min-w-full' : 'w-2.5 min-h-full'
            )}
            style={{
              backgroundColor: taskTemplate.teamColor ? `#${taskTemplate.teamColor}` : undefined,
            }}
          />
          <div className='flex-1 pl-1.5 pr-1 pt-0.5 pb-1'>
            {!collapsed && (
              <div className={cn('flex flex-row justify-between')}>
                <div className='font-bold py-1'>{displayTaskName || taskTemplate.name}</div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <div className='p-1 h-fit cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-900 transition-all'>
                      <DotsVerticalIcon className='w-3.5 h-3.5' />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={onRename}>
                      <InputIcon className='w-5 mr-2' /> {t('rename')}
                    </DropdownMenuItem>
                    <DropdownMenuItem className='text-destructive' onClick={onDelete}>
                      <TrashIcon className='w-5 mr-2' /> {t('delete')}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
            <div className={cn('flex flex-row items-center my-1.5', collapsed && 'justify-center')}>
              <ClockIcon className='w-4 h-4 mr-1.5 flex-shrink-0' />
              <div className='font-semibold'>
                {convertMinToHours(taskTemplate.estimatedDuration, collapsed)}
              </div>
            </div>
            {!collapsed && (
              <div className='py-0.5'>
                {formControl ? (
                  <Controller
                    name='teamId'
                    control={formControl}
                    render={({ field: { onChange, value } }) => (
                      <TeamSelector selectedTeamId={value} onChange={onChange} />
                    )}
                  />
                ) : (
                  <TeamSelector selectedTeamId={taskTemplate.teamId} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
