import { Locale } from 'date-fns';
import { enGB, fi } from 'date-fns/locale';

type SupportedLocales = {
  [key: string]: { iso639_2: string; displayName: string; dateTime: Locale };
};

export const SUPPORTED_LOCALES: SupportedLocales = {
  en: { iso639_2: 'eng', displayName: 'English', dateTime: enGB },
  fi: { iso639_2: 'fin', displayName: 'Suomi', dateTime: fi },
};
