import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * This function combines different class names.
 * @param inputs Unlimited number of class name parameters
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

/**
 * This function combines add a pseudo class to all the class inputs.
 * @param pseudoClass Pseudo class prefix
 * @param classInputs Class name inputs
 * @return String contains class names with pseudo class prefix.
 */
export const pseudoGroup = (pseudoClass: string, classInputs: string) =>
  classInputs.replace(/[^\s]+/g, (m) => `${pseudoClass.trim()}:${m}`);
