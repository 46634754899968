// https://stackoverflow.com/questions/72114775/vite-global-is-not-defined
window.global ||= window;
// Import React & ReactDOM for initialization
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// Providers
import { DataPlumberProvider } from 'data-plumber';
import { ThemeProvider } from '@/contexts';
// API endpoint configuration
import Config from './config';
// Main styles
import './index.css';
// Atomic components
import { ErrorBoundary, Spinner } from '@atoms';
// PostHog
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
// Sentry
import * as Sentry from '@sentry/react';
import { PlasterThemeProvider } from 'plaster-ui';
import { typography } from './config/plasterTheme';
import { useToast } from './hooks';
// Lazy-load the main component
// eslint-disable-next-line react-refresh/only-export-components
const App = lazy(() => import('./App'));

// Initialize PostHog when in production
if (!__DEV__) {
  posthog.init('phc_qVVA221KsB3fzxk71E9Qq7bCg5rEgAhB5xDaMvK35P0', {
    api_host: 'https://eu.posthog.com',
  });
}

// Initialize Sentry config
Sentry.init({
  enabled: !__DEV__, // Enable Sentry only in production
  dsn: 'https://3836c2bb7a870ea0de382baad20e10ef@o1010520.ingest.sentry.io/4506513091592192',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.mestamaster\.io/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const AppWrapper = () => {
  const { toast } = useToast();
  return (
    <PostHogProvider client={posthog}>
    <DataPlumberProvider
      envConfig={{
        url: Config.API_URL,
        wsUrl: Config.WS_URL,
        timeout: 10000,
        onErrorCallback: (error) => {
          let errorTitle: string
          switch (error.kind) {
            case "forbidden":
              errorTitle = "Forbidden"
              break
            case "cannot-connect":
              errorTitle = "Cannot connect"
              break
            default:
              errorTitle = "Error"
              break
          }
          toast({
            title: errorTitle,
            description: error.data?.data?.message,
            variant: 'destructive',
          });
        }
      }}
    >
      <ThemeProvider storageKey='mestamaster-theme' defaultTheme='light'>
        <Suspense fallback={<Spinner fullscren />}>
          <PlasterThemeProvider typographyOverride={typography}>
            <App />
          </PlasterThemeProvider>
        </Suspense>
      </ThemeProvider>
    </DataPlumberProvider>
  </PostHogProvider>
  );
}


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ErrorBoundary>
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>
  </ErrorBoundary>
);
