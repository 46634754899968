import { ReactSVG } from 'react-svg';
import { Button } from '@atoms';
import ErrorImage from '@images/error.svg';

type ErrorTemplate = {
  errorTitle: string;
  errorSubtitle?: string;
  actionBtnText: string;
  onActionBtnClick: () => void;
};

export const ErrorTemplate: React.FC<ErrorTemplate> = ({
  errorTitle,
  errorSubtitle,
  actionBtnText,
  onActionBtnClick,
}) => (
  <div
    role='alert'
    className='h-full w-full flex flex-col items-center justify-center text-center p-4'
    data-testid='error-template'
  >
    <div className='max-w-[500px] w-full'>
      <ReactSVG src={ErrorImage} />
      <div className='text-right text-xs text-gray-400'>
        Image by{' '}
        <a
          href='https://www.freepik.com/free-vector/hand-drawn-construction-background_1583775.htm#page=4&query=Construction%20mess%20error&position=4&from_view=search&track=ais&uuid=7fdd8078-85b2-44b3-a1d6-16e59a148ad0'
          target='_blank'
          rel='noopener noreferrer'
          className='underline underline-offset-4 hover:text-primary'
        >
          Freepik
        </a>
      </div>
    </div>
    <div className='my-4 text-center'>
      <h1 className='text-destructive dark:text-red-600' data-testid='error-template-title'>
        {errorTitle}
      </h1>
      {errorSubtitle && <h2 data-testid='error-template-subtitle'>{errorSubtitle}</h2>}
    </div>
    <Button preset='primary' shadowed onClick={onActionBtnClick}>
      {actionBtnText}
    </Button>
  </div>
);
