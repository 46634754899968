import { createContext, useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree';
import { models, GeneralApiProblem, MestamasterApiListResult } from 'data-plumber';
import {
  DndContext,
  closestCenter,
  rectIntersection,
  CollisionDetection,
  getFirstCollision,
  KeyboardSensor,
  pointerWithin,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/hooks';
import { Spinner } from '@atoms';
import { TaskCard } from '@/pages/TaktTrainTemplate/TaskTemplate';
import {
  TAKT_WAGON_TEMPLATE_DRAG_TYPE,
  TASK_TEMPLATE_DRAG_TYPE,
} from '@/pages/TaktTrainTemplate/constants';

type TaktTrainTemplateDnDProviderProps = {
  children: React.ReactNode;
  taktTrainTemplate: models.ITaktTrainTemplate;
  wagonTemplates: models.ITaktWagonTemplate[];
};

interface DnDTaskCard extends models.ITaskTemplate {
  pending?: boolean;
  teamColor: string | null;
}

type DraggingState = {
  taktWagonTemplateId: number;
  taskTemplates: models.ITaskTemplate[];
  refreshWagon: () => Promise<
    MestamasterApiListResult<models.ITaskTemplateSnapshotIn> | GeneralApiProblem
  >;
};

type TaktTrainTemplateDnDProviderState = {
  sourceWagonTemplate: DraggingState | null;
  targetWagonTemplate: DraggingState | null;
  draggingTaskCard: DnDTaskCard | null;
  wagonTemplates: models.ITaktWagonTemplate[];
};

const initialState: TaktTrainTemplateDnDProviderState = {
  sourceWagonTemplate: null,
  targetWagonTemplate: null,
  draggingTaskCard: null,
  wagonTemplates: [],
};

export const TaktTrainTemplateDnDContext =
  createContext<TaktTrainTemplateDnDProviderState>(initialState);

export const TaktTrainTemplateDnDProvider = observer(
  ({ children, taktTrainTemplate, wagonTemplates }: TaktTrainTemplateDnDProviderProps) => {
    const { t } = useTranslation();
    const { showModal, hideModal } = useModal();

    const [draggingType, setDraggingType] = useState<
      typeof TASK_TEMPLATE_DRAG_TYPE | typeof TAKT_WAGON_TEMPLATE_DRAG_TYPE | null
    >(null);
    const [draggingTaktWagonTemplate, setDraggingTaktWagonTemplate] =
      useState<models.ITaktWagonTemplate | null>(null);

    const [sourceWagonTemplate, setSourceWagonTemplate] = useState<DraggingState | null>(null);
    const [targetWagonTemplate, setTargetWagonTemplate] = useState<DraggingState | null>(null);
    const [draggingTaskCard, setDraggingTaskCard] = useState<DnDTaskCard | null>(null);
    const lastOverId = useRef<UniqueIdentifier | null>(null);

    /**
     * Custom collision detection strategy optimized for multiple containers
     *
     * - First, find any droppable containers intersecting with the pointer.
     * - If there are none, find intersecting containers with the active draggable.
     * - If there are no intersecting containers, return the last matched intersection
     *
     */
    const collisionDetectionStrategy: CollisionDetection = useCallback(
      (args) => {
        //  If the dragging type is a wagon template, only allow collision with other wagon templates (ie Columns)
        if (draggingType == TAKT_WAGON_TEMPLATE_DRAG_TYPE) {
          return closestCenter({
            ...args,
            droppableContainers: args.droppableContainers.filter(
              (container) => container.data?.current?.type === TAKT_WAGON_TEMPLATE_DRAG_TYPE
            ),
          });
        }
        // Start by finding any intersecting droppable
        const pointerIntersections = pointerWithin(args);
        const intersections =
          pointerIntersections.length > 0
            ? // If there are droppables intersecting with the pointer, return those
              pointerIntersections
            : rectIntersection(args);
        let overId = getFirstCollision(intersections, 'id');

        if (overId !== null) {
          // If the overId is a wagon template, check if it contains the active draggable
          if (overId in wagonTemplates.map((wagon: models.ITaktWagonTemplate) => wagon.id)) {
            // find the wagon that contains the overId
            const containerItems = wagonTemplates
              .find((wagon: models.ITaktWagonTemplate) => wagon.id === overId)
              ?.taskTemplatesArray.map((task: models.ITaskTemplate) => task.id.toString());

            // If a container is matched and it contains items
            if (containerItems && containerItems.length > 0) {
              // Return the closest droppable within that container
              overId = closestCenter({
                ...args,
                droppableContainers: args.droppableContainers.filter(
                  (container) =>
                    container.id !== overId && containerItems.includes(container.id.toString())
                ),
              })[0]?.id;
            }
          }

          lastOverId.current = overId;

          return [{ id: overId }];
        }

        // TODO -- TO FIX
        // // When a draggable item moves to a new container, the layout may shift
        // // and the `overId` may become `null`. We manually set the cached `lastOverId`
        // // to the id of the draggable item that was moved to the new container, otherwise
        // // the previous `overId` will be returned which can cause items to incorrectly shift positions
        // if (recentlyMovedToNewContainer.current) {
        //   lastOverId.current = draggingTaskCard?.id || draggingTaktWagonTemplate?.id;
        // }

        // If no droppable is matched, return the last match
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [draggingTaktWagonTemplate?.id, draggingTaskCard?.id]
    );

    const sensors = useSensors(
      useSensor(PointerSensor, {
        // Make sure normal click is not interpreted as a drag
        activationConstraint: {
          distance: 1,
        },
      }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    const handleDragStart = (event: DragStartEvent) => {
      const { data } = event.active;

      if (data.current?.type === TASK_TEMPLATE_DRAG_TYPE) {
        setDraggingType(TASK_TEMPLATE_DRAG_TYPE);
        handleDragTaskStart(event);
      } else {
        setDraggingType(TAKT_WAGON_TEMPLATE_DRAG_TYPE);
        handleDragWagonStart(event);
      }
    };

    const handleDragOver = (event: DragEndEvent) => {
      if (draggingType === TASK_TEMPLATE_DRAG_TYPE) {
        handleDragTaskOver(event);
      }
    };

    const handleDragEnd = async (event: DragEndEvent) => {
      if (draggingType === TASK_TEMPLATE_DRAG_TYPE) {
        handleDragTaskEnd(event);
      } else {
        handleDragWagonEnd(event);
      }
    };

    const handleDragCancel = () => {
      if (draggingType === TASK_TEMPLATE_DRAG_TYPE) {
        handleDragTaskCancel();
      } else {
        handleDragWagonCancel();
      }
    };

    /* 
      Takt wagon template drag handlers
    */
    const resetTaktWagonTemplateDragState = () => {
      setDraggingTaktWagonTemplate(null);
    };

    const handleDragWagonStart = (event: DragStartEvent) => {
      const draggingTaktWagonTemplate: models.ITaktWagonTemplate | undefined =
        event.active.data.current?.taktWagonTemplate;

      if (!draggingTaktWagonTemplate) return;
      setDraggingTaktWagonTemplate(draggingTaktWagonTemplate);
    };

    const handleDragWagonEnd = async (event: DragEndEvent) => {
      const newTaktWagonTemplateOrder: number | undefined =
        event.over?.data.current?.taktWagonTemplate.order;
      if (
        draggingTaktWagonTemplate &&
        typeof newTaktWagonTemplateOrder === 'number' &&
        draggingTaktWagonTemplate.order !== newTaktWagonTemplateOrder
      ) {
        showModal({
          content: (
            <div className='flex flex-col items-center text-center'>
              <div>
                <Spinner width={50} />
              </div>
              <div className='font-heading pt-3'>{t('taktTemplate:wagon.movingWagons')}</div>
            </div>
          ),
          closeDisabled: true,
        });
        await taktTrainTemplate.updateTaktWagonTemplate(draggingTaktWagonTemplate.id, {
          order: newTaktWagonTemplateOrder,
        });
        await taktTrainTemplate.fetchTaktWagonTemplates(true);
        hideModal();
      }
      resetTaktWagonTemplateDragState();
    };

    const handleDragWagonCancel = () => {
      resetTaktWagonTemplateDragState();
    };

    /* 
      Task template drag handlers
    */

    const resetTaskTemplateDragState = () => {
      setDraggingTaskCard(null);
      setSourceWagonTemplate(null);
      setTargetWagonTemplate(null);
    };

    const handleDragTaskStart = (event: DragStartEvent) => {
      const { data } = event.active;

      const draggingTaskCard: models.ITaskTemplate | undefined = data.current?.taskTemplate;
      const sourceTaktWagonTemplate: models.ITaktWagonTemplate | undefined =
        data.current?.taktWagonTemplate;

      if (
        !draggingTaskCard ||
        !sourceTaktWagonTemplate ||
        !isStateTreeNode(draggingTaskCard) ||
        !isStateTreeNode(sourceTaktWagonTemplate)
      )
        return;

      setDraggingTaskCard({
        ...(getSnapshot(draggingTaskCard) as DnDTaskCard),
        teamColor: data.current?.teamColor,
      });

      setSourceWagonTemplate({
        taktWagonTemplateId: sourceTaktWagonTemplate.id,
        taskTemplates: sourceTaktWagonTemplate.taskTemplatesArray.filter(
          (task) => task.id !== draggingTaskCard.id
        ),
        refreshWagon: sourceTaktWagonTemplate.fetchTaskTemplates,
      });
    };

    const handleDragTaskOver = (event: DragEndEvent) => {
      const targetTaktWagonTemplate: models.ITaktWagonTemplate | undefined =
        event.over?.data.current?.taktWagonTemplate;

      if (
        !sourceWagonTemplate ||
        !targetTaktWagonTemplate ||
        !draggingTaskCard ||
        targetTaktWagonTemplate.id === targetWagonTemplate?.taktWagonTemplateId ||
        targetTaktWagonTemplate?.buffer
      )
        return;

      if (
        targetTaktWagonTemplate.taskTemplatesArray.length === 0 ||
        (targetTaktWagonTemplate.taskTemplatesArray.length === 1 &&
          targetTaktWagonTemplate.taskTemplatesArray[0].id === draggingTaskCard.id)
      ) {
        setTargetWagonTemplate({
          taktWagonTemplateId: targetTaktWagonTemplate.id,
          taskTemplates: [draggingTaskCard],
          refreshWagon: targetTaktWagonTemplate.fetchTaskTemplates,
        });
        return;
      }

      const overWagonTaskTemplates =
        targetTaktWagonTemplate.id === sourceWagonTemplate.taktWagonTemplateId
          ? sourceWagonTemplate.taskTemplates
          : targetTaktWagonTemplate.taskTemplatesArray;

      setTargetWagonTemplate({
        taktWagonTemplateId: targetTaktWagonTemplate.id,
        taskTemplates: [...overWagonTaskTemplates, draggingTaskCard],
        refreshWagon: targetTaktWagonTemplate.fetchTaskTemplates,
      });
    };

    const handleDragTaskEnd = async (event: DragEndEvent) => {
      const { active, over } = event;

      if (!sourceWagonTemplate || !targetWagonTemplate || !draggingTaskCard) return;

      const activeTaktWagonTemplate: models.ITaktWagonTemplate =
        active.data.current?.taktWagonTemplate;
      const activeTaskTemplate = active.data.current?.taskTemplate as models.ITaskTemplate;
      const overTaskTemplate = over?.data.current?.taskTemplate as models.ITaskTemplate;

      if (activeTaskTemplate && overTaskTemplate && targetWagonTemplate) {
        // Get the index of the active and over task templates
        // The index of over task template will be used to update later
        const activeIndex = targetWagonTemplate.taskTemplates.findIndex(
          (wagonTemplateTask) => wagonTemplateTask.id === activeTaskTemplate.id
        );
        const overIndex = targetWagonTemplate.taskTemplates.findIndex(
          (wagonTemplateTask) => wagonTemplateTask.id === overTaskTemplate.id
        );

        // If either index is not found, return
        if (activeIndex === -1 || overIndex === -1) return;

        // Move the task template to the new index (for UI only)
        setTargetWagonTemplate({
          ...targetWagonTemplate,
          taskTemplates: arrayMove(targetWagonTemplate.taskTemplates, activeIndex, overIndex),
        });

        // Set the pending state to show a prevent interaction
        setDraggingTaskCard({ ...draggingTaskCard, pending: true });

        // Use over index (de facto order) to update the task template order in the backend
        await activeTaktWagonTemplate.updateTaskTemplate(activeTaskTemplate.id, {
          taktWagonTemplateId: targetWagonTemplate.taktWagonTemplateId,
          wagonOrder: overIndex,
        });

        // Refresh the source and target wagons
        // TODO: This can be optimized by reordering in MobX
        await sourceWagonTemplate.refreshWagon();
        await targetWagonTemplate.refreshWagon();
      }

      resetTaskTemplateDragState();
    };

    const handleDragTaskCancel = () => {
      resetTaskTemplateDragState();
    };

    return (
      <TaktTrainTemplateDnDContext.Provider
        value={{
          sourceWagonTemplate,
          targetWagonTemplate,
          draggingTaskCard,
          wagonTemplates,
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={collisionDetectionStrategy}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          autoScroll={{ acceleration: 1 }}
        >
          {children}
          <DragOverlay>
            {draggingTaskCard && (
              <TaskCard taskTemplate={draggingTaskCard} className='min-w-[220px]' />
            )}
          </DragOverlay>
        </DndContext>
      </TaktTrainTemplateDnDContext.Provider>
    );
  }
);
